import { useState } from 'react';
import {
    Form
    , Row
    , Col
    , Input
    , Button
    , DatePicker
    , Select
    , Space
} from 'antd';
import "./index.css"
const { RangePicker } = DatePicker
const { Option } = Select
const SearchBar = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const onFinish = (values) => {
        setLoading(true)
        //清楚空属性
        for (const key in values) {
            if (values[key]) {
                continue
            } else {
                delete values[key]
            }
        }
        //onSearch是async函数 n为返回的promist
        const resultPromise = props.onSearch(values)
        //如果promisi是成功状态说明查询已经完毕
        resultPromise.then(res => {
            setLoading(false)
        })
    };
    return (
        <div className="cardlog-search-bar">
            <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{
                    op_type: '0'
                }}
            >
                <Row gutter={10}>
                    <Col span={6}>
                        <Form.Item
                            label="公司名称"
                            name="company_name"
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="操作类型"
                            name="op_type"
                        >
                            <Select>
                                <Option value="0">全部</Option>
                                <Option value="1">划出</Option>
                                <Option value="2">收入</Option>
                                <Option value="3">消耗</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="range_time" label="操作时间" >
                            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                    <Col
                        span={4}
                    >
                        <Space>
                            <Button loading={loading} type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button

                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Space>

                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default SearchBar