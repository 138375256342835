import { useRef } from 'react'
import { Modal, Form, Switch } from "antd"
export default function BatchOpenPosition(props) {
    const [form] = Form.useForm();
    function onOk(e) {
        // console.log(formRef.current);
        // console.log();
        form.validateFields()
            .then(values => {
                // console.log(values);
                props.onSubmit(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
        // form.validateFields()
        // .then(values=>{
        //     this.props.onSubmit(values)
        // })
        // .catch(info => {
        //     console.log('Validate Failed:', info);
        // });
    }
    return (
        <Modal
            open={props.visible}
            onCancel={props.onCancel}
            title="批量设置定位开关"
            onOk={onOk}
        >
            <Form
                form={form}
                initialValues={{
                    isOpen: false
                }}
            >
                <Form.Item
                    valuePropName='checked'
                    name="isOpen"
                    label="定位开关"
                >
                    <Switch />
                </Form.Item>
            </Form>

        </Modal>
    )
}
