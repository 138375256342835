//分配可添加用户数
import { useState } from "react"
import { Form, Modal, InputNumber, Input, Select, Radio } from "antd"
import { SwapRightOutlined } from "@ant-design/icons"
import { } from "../../../../api"
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const AssignCard = ({ visible, onCancel, onSubmit, currentEdit }) => {
    const [form] = Form.useForm()
    function onCardTypeChange({ voice_card, gps_card }) {
        if (voice_card || gps_card) {
            form.setFieldsValue({ voice_card, gps_card })
        }
    }
    return (
        <Modal

            open={visible}
            title={<span>分配充值卡<SwapRightOutlined />{currentEdit.companyName}</span>}
            okText="分配"
            cancelText="取消"
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {

                        let pram = {};
                        pram.serviceType = values.voice_card ? 1 : 2
                        pram.cardType = values.voice_card || values.gps_card
                        pram.count = values.count
                        onSubmit(pram, form);
                        setTimeout(() => {
                            form.resetFields();
                        }, 1000);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form {...formItemLayout}
                initialValues={{ voice_card: 1, count: 1, operation_type: 1 }}
                form={form}
                onValuesChange={onCardTypeChange}
            >
                {/* <Form.Item
                    label="操作"
                    name="operation_type"
                >
                    <Select >
                        <Select.Option value={1}>分配</Select.Option>
                        <Select.Option value={2}>回收</Select.Option>
                    </Select>
                </Form.Item> */}
                <Form.Item
                    label="语音"
                    name="voice_card"
                >
                    <Radio.Group buttonStyle="solid" >
                        <Radio.Button value={1}>永久</Radio.Button>
                        <Radio.Button value={2}> 年卡</Radio.Button>
                        <Radio.Button value={3}>月卡</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="定位"
                    name="gps_card"
                >
                    <Radio.Group buttonStyle="solid" >
                        <Radio.Button value={2}> 年卡</Radio.Button>
                        <Radio.Button value={3}>月卡</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="数量"
                    name="count"
                    tooltip="数量为负时回收充值卡"
                    rules={[
                        {
                            pattern: /^-?[1-9]{1}\d{0,2}$/,
                            message: '合法值：-999~999',
                        },
                    ]}
                >
                    <InputNumber></InputNumber>
                </Form.Item>

                {/* <Form.Item
                    label="备注"
                    name="remarks"
                >
                    <Input.TextArea />
                </Form.Item> */}
            </Form>
        </Modal>
    )
}

export default AssignCard