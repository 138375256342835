import { useState, useRef } from "react"
import { Form, Input, Select, Transfer, Button, Steps, message, Space } from "antd"
import { connect } from "react-redux";
const { Item } = Form
const { Option } = Select
const formItemLayout = {
    labelCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 8,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 6,
        },
    },
};
const AddForm = ({ onNextStep, onSubmit,groups,companies }) => {
    const [form] = Form.useForm()
    //选择公司
    const [currentCompany, subCompanys] = companies;
    const [tansferVisible, setTransferVisible] = useState(false)
    //已选中的关联群组
    const [selectedGroups, setSelectedGroups] = useState([])
    const onSelectedChange = (values) => {
        setSelectedGroups(values)
    }
    const createGroup = () => {
        form
            .validateFields()
            .then(values => {
                values.union_group = selectedGroups
                onSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }
    const submit = () => {
        form
            .validateFields()
            .then(values => {
                values.union_group = selectedGroups
                onNextStep(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }
    return (
        <Form
            name="group_info"
            form={form}
            {...formItemLayout}
            onFinish={submit}
            initialValues={{
                group_type: 1
            }}
        >
            <Item
                label="群组名称"
                name="group_name"
                rules={[
                    {
                        required: true,
                        message: '请输入群组名称!',
                        whitespace: true,
                    }, {
                        pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{1,8}$/,
                        message: '字母、汉字或数字，长度不能超过8位'
                    }
                ]}
            >
                <Input />
            </Item>
            <Item
                label="群组类型"
                name="group_type"
            >
                <Select
                    onChange={value => {
                        if (value === 3) {
                            setTransferVisible(true)
                        } else {
                            setTransferVisible(false)
                        }
                    }}>
                    <Option value={1}>普通群组</Option>
                    {/* <Option value={2}>超级调度</Option>
                    <Option value={3}>关联组</Option> */}
                </Select>
            </Item>
            <Item
                label="所属公司"
                name="company_id"
                rules={[
                    { required: true, message: "必须选择所属公司" }
                ]}
            >
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        option.children.indexOf(input) >= 0
                    }
                    placeholder="选择所属公司">

                    {
                        <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                    }
                    {
                        subCompanys.map(item => {
                            //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                            return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                        })
                    }
                </Select>
            </Item>

            {
                tansferVisible ? (
                    <Item
                        label="关联"
                    >
                        <Transfer
                            listStyle={{
                                width: 250,
                                height: 400,
                            }}
                            dataSource={groups.map(item => {
                                item.key = item.group_id
                                return item
                            })}
                            render={item => item.group_name}
                            targetKeys={selectedGroups}
                            onChange={onSelectedChange}
                            titles={['群组列表', '关联列表']}
                        ></Transfer>
                    </Item>
                ) : null
            }

            <Item
                {...tailFormItemLayout}
            >
                <Space>
                    <Button type="primary" onClick={createGroup}>创建</Button>
                    <Button type="primary" htmlType="submit">选择成员</Button>
                </Space>
            </Item>
        </Form>
    )
}
export default connect(
    state=>({
        groups:state.groups,
        companies:state.companies
    })
)(AddForm)