import { useEffect, useState } from "react";
import { Table, message } from "antd"
import SearchBar from "./search-bar"
import { reqGetUserModifyLogs } from "../../../api"
import moment from "moment";
import "./account-log.css"
const AccountLog = () => {
    const [options, setOptions] = useState()

    //分页状态
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ current: 1, pageSize: 30 });
    const [tableLoading, setTableLoading] = useState()
    const [data, setData] = useState([])
    const [currentEdit, setCurrentEdit] = useState({})
    useEffect(() => {
        const getUserModifyLogs = async () => {
            const { current, pageSize } = page
            setTableLoading(true)
            const res = await reqGetUserModifyLogs({ ...options, page_size: pageSize, page_index: current })
            setTableLoading(false)
            if (res.code === 200) {
                //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
                const list = res.data.list.map((item, index) => {
                    item.index = index
                    return item
                })
                setTotal(res.data.row_count);
                setData(list)
            } else {
                message.error("获取修改账号日志失败")
            }

        }
        getUserModifyLogs()
    }, [page, options])
    const onSearch = async (options) => {
        const range = options.range_time
        if (range) {
            options.start_time = moment(new Date(range[0])).format('{YYYY}/{Mo}/{DD} {H}:{mm}:{ss}')
            options.end_time = moment(new Date(range[1])).format('{YYYY}/{Mo}/{DD} {H}:{mm}:{ss}')
        }
        delete options.range_time
        //搜索
        const { current, pageSize } = page
        // const res = await reqAccountList({ ...options, page_size: pageSize, page_index: 1 })
        setOptions(options)
        setPage({ current: 1, pageSize })
        // setTotal(res.data.row_count);
        // setData(res.data.user_list)
    }
    const handleTableChange = (page) => {
        setPage(page)
    }
    const columns = [{
        title: '时间',
        dataIndex: 'time',
        align: 'center'
    }, {
        title: '用户账号',
        dataIndex: 'user_account',
        align: 'center'
    }, {
        title: '所属公司',
        dataIndex: 'company_name',
        align: 'center'
    }, {
        // 可选参数，0为全部，操作类型：1 添加, 2编辑, 3删除, 4批量添加，5批量导入
        title: '操作类型',
        dataIndex: 'op_type',
        align: 'center',
        render:
            value => {
                if (value === 1) {
                    return <span>添加</span>
                } else if (value === 2) {
                    return <span>编辑</span>
                } else if (value === 3) {
                    return <span>删除</span>
                } else if (value === 4) {
                    return <span>批量添加</span>
                } else if (value === 5) {
                    return <span>批量导入</span>
                } else if (value === 9) {
                    return <span>重置</span>
                }
            }

    }, {
        title: '操作内容',
        ellipsis: true,
        align: 'center',
        dataIndex: 'contents'
    }, {
        title: '操作IP',
        align: 'center',
        dataIndex: 'ip'
    }]
    return (
        <div className="account-log">
            <div className="account-log-search">
                <SearchBar onSearch={onSearch} />
            </div>
            <div className="account-log-table">
                <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    pagination={{ total: total, defaultPageSize: page.pageSize, pageSizeOptions: [10, 30, 50, 100], current: page.current, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
                    rowKey="index"
                    loading={tableLoading}
                    onChange={handleTableChange}
                    bordered
                ></Table>
            </div>
        </div>
    )
}

export default AccountLog