import React, { useEffect, useState, useSyncCallback } from 'react'
import SearchBar from "./SearchBar"
import BatchEditPosition from "./BatchEditPosition"
import BatchOpenPosition from "./BatchOpenPosition"
import "./index.css"
import { Table, Switch, message, Space, Button, Flex, theme } from 'antd'
import { reqTerminalList, reqEditTempCall, reqEditOpenPosition, reqEditPosition } from "../../../api"
import { EnvironmentOutlined, AimOutlined } from "@ant-design/icons"
import Detail from './Detail'

//设置单呼 
const editTempCall = async (checked, value, record) => {
    const { userId } = record
    const res = await reqEditTempCall(userId, checked)
    if (res.data.result === 1) {
        message.success("操作成功！")
    }
}


function TerminalList() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [searchOps, setSearchOps] = useState({ pageNum: 1, pageSize: 30 })
    const [rowCount, setRowCount] = useState(0)
    const [editPositionVisiable, setEditPositionVisiable] = useState(false)
    const [openPositionVisiable, setOpenPositionVisiable] = useState(false)
    const [curTerminalId, setCurTerminalId] = useState()
    const [detailOpen, setDetailOpen] = useState(false)
    const {
        token: { colorBgContainer }
    } = theme.useToken()
    useEffect(() => {
        getTerminalList()
    }, [searchOps])
    const columns = [
        { title: '登录账号', dataIndex: 'userAccount' },
        { title: '终端名称', dataIndex: 'name' },
        { title: 'ICCID', dataIndex: 'iccid' },
        { title: 'IMEI', dataIndex: 'imei' },
        { title: '定位ID', dataIndex: 'deviceId' },
        { title: '位置服务地址', dataIndex: 'locationServer', render: (value, record) => { return <span>{value ? `${value}:${record.locationPort}` : ""}</span> } },
        { title: '单呼', dataIndex: 'tempCall', render: (value, record) => <Switch size="small" onClick={(checked) => { editTempCall(checked, value, record) }} defaultChecked={value} /> },
        { title: '定位', dataIndex: 'openPosition', render: (value, record) => <Switch size="small" onClick={(checked) => { editOpenPosition(checked, value, record); }} checked={value} loading={record.openGpsLoading} /> },
        { title: '定位间隔', dataIndex: 'locationInterval', render: (value) => { return value || '30' } },
        {
            title: '操作',
            render: (text, record, index) => {
                return <Button type="link" size="small" onClick={() => {
                    setCurTerminalId(record.userId)
                    setDetailOpen(true)
                }}>更多</Button>
            }
        }
    ]
    const editOpenPosition = async (checked, value, record) => {
        // debugger
        const { userId } = record
        let checkResult = false
        const nData1 = data.map(item => {
            if (item.userId === userId) {
                item.openGpsLoading = true
            }
            return item
        })
        setData(nData1)
        const opts = {
            userIds: [userId],
            isOpen: checked
        }
        const res = await reqEditOpenPosition(opts)
        if (res.success) {
            checkResult = checked
            message.success("操作成功！")
        } else {
            checkResult = !checked
            message.warning(res.msg)
        }
        const nData = data.map(item => {
            if (item.userId === userId) {
                item.openPosition = checkResult
                item.openGpsLoading = false
            }
            return item
        })
        setData(nData)
    }
    const batchEditOpenPosition = async ({ isOpen }) => {
        console.log(isOpen, selectedRowKeys);
        const opts = {
            userIds: selectedRowKeys,
            isOpen: isOpen
        }
        const res = await reqEditOpenPosition(opts)
        if (res.success) {
            setOpenPositionVisiable(false)
            if (res.msg) {
                message.warning(res.msg)
            } else {
                message.success("操作成功!")
            }
            updataTable()
        } else {
            message.warning(res.msg)
        }
    }
    const getTerminalList = async () => {

        setLoading(true)
        const res = await reqTerminalList(searchOps)
        setLoading(false)
        if (res.success) {
            setSelectedRowKeys([])
            setData(res.data.list)
            setRowCount(res.data.rowCount)
        }
    }
    const onSelectChange = selectedRow => {
        setSelectedRowKeys(selectedRow)
    }
    //批量设置位置服务
    const editPosition = async (values) => {
        const options = {
            userIds: selectedRowKeys,
            ...values
        }
        const res = await reqEditPosition(options)
        if (res.success) {
            setEditPositionVisiable(false)
            //更新表格
            message.success("设置位置服务成功")
            updataTable()
        }
    }
    //更新表格
    const updataTable = () => {
        getTerminalList()
    }
    const cancelEditPosition = () => {
        setEditPositionVisiable(false)
    }
    const cancelOpenPosition = () => {
        setOpenPositionVisiable(false)
    }
    const onTableChange = (page, filters, sorter) => {
        const { field, order } = sorter

        // //如果不排序 删除掉排序属性
        setSearchOps({ ...searchOps, pageNum: page.current, pageSize: page.pageSize })
    }
    const onSearch = (options) => {
        const { pageSize } = searchOps
        options.pageNum = 1
        options.pageSize = pageSize
        setSearchOps(options)
    }
    const hasSelected = selectedRowKeys.length > 0;
    return (
        <div className="terminal-list">
            <SearchBar onSearch={onSearch}></SearchBar>
            <Flex vertical style={{ background: colorBgContainer }}>
                <Flex align="center" gap="small" wrap style={{ padding: 5 }} >
                    <Button type="primary" icon={<EnvironmentOutlined />} disabled={!hasSelected} onClick={() => { setEditPositionVisiable(true) }}>
                        设置位置服务地址
                    </Button>
                    <Button type="primary" icon={<AimOutlined />} disabled={!hasSelected} onClick={() => { setOpenPositionVisiable(true) }}>
                        定位开关
                    </Button>

                    {hasSelected ? `已选中 ${selectedRowKeys.length} 行` : ''}

                </Flex>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    size="small"
                    rowKey='userId'
                    bordered
                    onChange={onTableChange}
                    pagination={{ total: rowCount, defaultPageSize: searchOps.pageSize, pageSizeOptions: [10, 30, 50, 100], current: searchOps.pageNum, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                >
                </Table>
            </Flex>
            <Detail open={detailOpen} id={curTerminalId} closeDetail={() => { setDetailOpen(false) }} />
            <BatchEditPosition visible={editPositionVisiable} onCancel={cancelEditPosition} onSubmit={editPosition}></BatchEditPosition>
            <BatchOpenPosition visible={openPositionVisiable} onCancel={cancelOpenPosition} onSubmit={batchEditOpenPosition} />
        </div >
    )
}
export default TerminalList