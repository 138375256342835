//项目入口文件
import React from 'react'
import ReactDom from 'react-dom/client'
import { RouterProvider,createHashRouter } from "react-router-dom"
import router from "./router"
import { ConfigProvider } from "antd"
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from "react-redux"
import persistStore from './redux/store'
import { StyleProvider, legacyLogicalPropertiesTransformer } from "@ant-design/cssinjs"
import uaParser from 'ua-parser-js'
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/locale/zh_CN';
import 'antd/dist/reset.css';
import "./index.css"
dayjs.locale('zh-cn');
const { store, persistor } = persistStore
const root = ReactDom.createRoot(document.getElementById('root'));
const getHashPriorty = () => {
    let ua = uaParser(navigator.userAgent)
    const { browser } = ua
    let major = browser.version.split('.')[0] - 0
    if (browser.name === 'Chrome' && major > 88) {
        return 'low'
    } else if (browser.name === 'Firefox' && major > 78) {
        return 'low'
    } else if (browser.name === 'Safari' && major > 14) {
        return 'low'
    } else {
        return 'high'
    }
}
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ConfigProvider
                locale={zhCN}
                theme={{
                    token: {
                        // borderRadius: 0,
                    }
                }}
            >
                <StyleProvider
                    //https://ant.design/docs/react/compatible-style-cn 解决样式兼容问题
                    hashPriority={getHashPriorty()}
                    transformers={[legacyLogicalPropertiesTransformer]}>
                    <RouterProvider router={createHashRouter(router)}/>
                </StyleProvider>
            </ConfigProvider>
        </PersistGate>
    </Provider>
)
