//批量激活模态框组件
import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
const EditRemark = ({ visible, onSubmit, onCancel, currentRemark }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            remark: currentRemark
        })
    }, [currentRemark])
    return (
        <Modal
            open={visible}
            title="备注"
            okText="确定"
            cancelText="取消"
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values, form);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="inline"
                name="form_in_modal"
            >
                <Form.Item
                    name="remark"
                    label="修改备注"
                >
                    <Input></Input>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default EditRemark
