import {  Modal, Form, Select } from 'antd';
import { connect } from 'react-redux';
const { Option } = Select
const BatchEditDefaultGroup = ({ visible, onCancel, onSubmit,groups }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            open={visible}
            title="请选择群组"
            okText="确定"
            cancelText="取消"
            onCancel={()=>{
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="inline"
                name="form_in_modal"
            >
                <Form.Item
                    name="group_id"
                    label="群组"
                    rules={[{ required: true, message: '请选择要设置的群组' }]}
                >
                    <Select style={{ width: 200 }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.indexOf(input) >= 0
                        }
                        placeholder="请选择"
                    >

                        {

                            groups.map(item => {
                                return <Option key={item.group_id} value={item.group_id} >{item.group_name}</Option>
                            })
                        }

                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
}
export default connect(
    state=>({
        groups:state.groups
    })
)(BatchEditDefaultGroup)