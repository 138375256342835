import { useState } from "react"
import { Form, Input, Select, Transfer, Button, Steps, message, Card, Row, Col } from "antd"
import AddForm from "./add-form";
import SelectMember from "./select-member";
import AddResult from "./add-result";
import { reqCreateGroup, reqGetGroup } from "../../../api";
import { update_groups } from "../../../redux/actions"
import "./index.css";
import { connect } from "react-redux";
const { Option } = Form
const { Step } = Steps;


const GroupAdd = props => {
    const [current, setCurrent] = useState(0);
    const [addOptions, setAddOptions] = useState({})
    const [unionGroups, setUnionGroups] = useState([])

    //去选择成员
    const goSelectMember = (values) => {
        setCurrent(current + 1);
        delete values.union_group
        setAddOptions(values)
    };
    //创建群组
    const carateGroup = async (values) => {
        delete values.union_group
        setAddOptions(values)
        const res = await reqCreateGroup(values)
        if (res.data?.result == 1) {
            setCurrent(2)
            setTimeout(() => { //创建新群组成功后 更新redux groups
                updateReduxGroups()
            }, 500);
        } else {
            message.error(res.data.err_msg)
        }
    };
    //选择成员后 完成创建
    const complate = async (values) => {
        const res = await reqCreateGroup({ ...addOptions, user_ids: values.join(",") })
        if (res.data?.result == 1) {
            setCurrent(2)
            setTimeout(() => { //创建新群组成功后 更新redux groups
                updateReduxGroups()
            }, 500);
        } else {
            message.error(res.data.err_msg)
        }
    }
    const prevStep1 = () => {

        setCurrent(0);
    };
    //新增群组完成后 更新到reduex中
    const updateReduxGroups = async () => {
        const res = await reqGetGroup()
        if (res.code === 200) {
            props.update_groups(res.data.group_list)
        }
    }
    return <div className="group-add">
        <Card
            title="新增群组"
            className="group-add-card"
            bordered={false}
        >
            <Row>
                <Col
                    span={6}
                >
                </Col>
                <Col span={8}>
                    <Steps
                        current={current}
                        className="steps"
                    >
                        <Step title="群组信息" />
                        <Step title="选择成员" />
                        <Step title="完成" />
                    </Steps>
                </Col>
            </Row>
            <Row>
                <Col
                    span={6}
                >
                </Col>
                <Col span={18}>
                    <div className="steps-content">
                        {current === 0 && (
                            <AddForm onNextStep={goSelectMember} onSubmit={carateGroup}></AddForm>
                        )}
                        {current === 1 && (
                            <SelectMember onSubmit={complate}></SelectMember>
                        )}
                        {current === 2 && (
                            <AddResult prev={prevStep1}></AddResult>
                        )}
                    </div>
                </Col>
            </Row>

        </Card>
    </div>
}
export default connect(
    null,
    { update_groups }
)(GroupAdd)