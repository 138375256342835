
import { Form, Space, Table, Popconfirm, Row, Col, Input, Descriptions, Statistic, Card, message, Menu, Dropdown, Button, Select } from "antd"
import { MacCommandOutlined } from "@ant-design/icons"
import { useState, useEffect } from "react"
import { PlusCircleTwoTone, PhoneTwoTone, IdcardTwoTone, DownOutlined, BankTwoTone, CreditCardTwoTone, ProjectTwoTone } from '@ant-design/icons';
import { reqGetCompanyList, reqAssignNumAdd, reqAssignCard, reqEditSubCompany, reqDeleteCompany } from "../../../api"
import AssignNumAdd from "./assign-num-add"
import RollBackNumAdd from "./rollback-num-add/rollback-num-add"
import AssignCard from "./assign-card"
import EditSubCompany from "./edit-subcompany"
import { delete_company_by_id } from "../../../redux/actions"
import "./index.css"
import { useSelector, useDispatch } from "react-redux";
const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
  {
    key: '3',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        3rd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
  {
    key: '4',
    danger: true,
    label: 'a danger item',
  },
];
const CompanyList = props => {
  //当前编辑公司
  const [currentEdit, setCurrentEdit] = useState({})
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(false)
  //搜索关键字
  const [subcompanyName, setSubcompanyName] = useState()
  const [page, setPage] = useState({
    pageSize:30,
    pageNum:1,
    total:0
  })
  //显示分配添加用户
  const [showAssignNumAdd, setShowAssignNumAdd] = useState(false)
  const [showRollBackNumAdd, setShowRollBackNumAdd] = useState(false)
  const [currentCompany, subCompanys] = useSelector(state => state.companies)
  const [selectedCompany, setSelectedCompany] = useState();
  const dispatch = useDispatch()
  //分配添加用户打开模态框
  const openAssignNumAdd = (record) => {
    setShowAssignNumAdd(true)
    setCurrentEdit(record)
  }
  const openRollBackNumAdd = (record) => {
    setShowRollBackNumAdd(true)
    setCurrentEdit(record)
  }
  //分配添加用户关闭模态框
  const closeAssignNumAdd = () => {
    setShowAssignNumAdd(false)
  }
  const closeRollBackNumAdd = () => {
    setShowRollBackNumAdd(false)
  }
  //确认分配可添加用户数
  const assignNumAdd = async (values) => {
    const res = await reqAssignNumAdd(currentEdit.companyId, values.assign_count)
    if (res.data?.result === 1) {
      message.success("分配成功")
      updateTable()
      closeAssignNumAdd()
    } else {

    }
  }
  //收回可添加用户数
  const rollBackNumAdd = async (values) => {
    const res = await reqAssignNumAdd(currentEdit.companyId, -values.assign_count)
    if (res.data?.result === 1) {
      message.success("收回成功")
      updateTable()
      closeRollBackNumAdd()
    } else {

    }
  }
  //显示分配年卡
  const [showAssignCard, setShowAssignCard] = useState(false)
  const openAssignCard = (record) => {
    setShowAssignCard(true)
    setCurrentEdit(record)
  }
  const closeAssignCard = () => {
    setShowAssignCard(false)
  }
  // /确认分配年卡
  const assignCard = async (values) => {
    let options = values
    options.companyId = currentEdit.companyId
    const res = await reqAssignCard(options)
    if (res.success) {
      message.success("操作成功")
    } else {
      message.error(res.msg)
    }
    closeAssignCard()
  }
  const [showEditSubCompany, setShowEditSubCompany] = useState(false)
  const openEditSubCompany = record => {
    setCurrentEdit(record)
    setShowEditSubCompany(true)
  }
  const closeEditSubCompany = () => {
    setShowEditSubCompany(false)
  }
  const editSubCompany = async (values) => {
    values.companyId = currentEdit.companyId
    values.userName = values.name
    const res = await reqEditSubCompany(values)
    if (res.success) {
      message.success("修改成功")
      closeEditSubCompany()
    } else {
      message.error(res.data.err_msg)
    }

  }
  const confirmDeleteCompany = async (record) => {
    const { companyId } = record
    const res = await reqDeleteCompany(companyId)
    if (res.data?.result === 1) {
      message.success("删除成功")
      updateTable()
      //更新redux
      dispatch(delete_company_by_id(companyId))
    } else {
      message.error(res.data?.err_msg)
    }
  }
  //刷新表格
  const updateTable = async () => {
    const res = await reqGetCompanyList({subcompanyName,selectedCompany,pageSize: page.pageSize,pageNum: page.pageNum})
    if(res.success) {
      setCompanyList(res.data.subcompanyList)
      setPage(page=>({total: res.data.totalCount}))
    }
  }
  const onSearch = async (value) => {
    setSubcompanyName(value.subcompanyName)
    setSelectedCompany(value.companyId)
    setPage(page=>({...page,pageNum: 1}))
    setLoading(true)
    const res = await reqGetCompanyList({...value,pageSize: page.pageSize,pageNum: 1})
    setLoading(false)
    if (res.success) {
      setCompanyList(res.data.subcompanyList)
      setPage(page=>({...page,pageNum: 1,total:res.data.totalCount}))
    }
  }
  useEffect(() => {
    const getCompanyList = async () => {
      setLoading(true)
      const res = await reqGetCompanyList({pageSize: page.pageSize,pageNum: page.pageNum})
      setLoading(false)
      if (res.success) {
        setCompanyList(res.data.subcompanyList)
      } else {
        message.warning(res.msg)
      }

    }
    getCompanyList()
  }, [])
  const columns = [
    {
      title: '公司名称',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '账号数量',
      dataIndex: 'userCount',
      key: 'userCount',
    },
    {
      title: '群组数量',
      dataIndex: 'groupCount',
      key: 'groupCount',
    },
    {
      title: '账号预警数量',
      dataIndex: 'alarmCount',
      key: 'alarmCount',
      responsive: ['sm'],
    },
    {
      title: '过期账号',
      dataIndex: 'expireCount',
      key: 'expireCount',
    },
    {
      title: "剩余添加用户数",
      dataIndex: "numAddUsers",
      key: "numAddUsers",
    }, {
      title: "操作",
      render: (record) => {
        return (
          <>
            <Dropdown
              menu={{
                items: [
                  { label: '分配', key: 'fenpei' },
                  { label: '回收', key: 'huishou' }
                ],
                onClick: (e) => {
                  if (e.key === 'fenpei') {
                    openAssignNumAdd(record)
                  } else if ("huishou") {
                    openRollBackNumAdd(record)
                  }
                }
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                添加用户数
                <DownOutlined />
              </a>
            </Dropdown>
            {/* Button type="link" onClick={() => { openAssignNumAdd(record) }}>分配可添加用户数</Button> */}
            {/* Button type="link" onClick={() => { openAssignNumAdd(record) }}>回收可添加用户数</Button> */}
            <Button type="link" size="small" onClick={() => { openAssignCard(record) }}> 分配充值卡</Button >
            <Button type="link" size="small" onClick={() => { openEditSubCompany(record) }}> 修改</Button >
            <Popconfirm
              title="公司删除后不可恢复"
              onConfirm={() => { confirmDeleteCompany(record) }}
              okText="删除"
              cancelText="取消"
            >
              <Button type="link" size="small">删除</Button>
            </Popconfirm >
          </>
        )
      }
    }

  ]
const onPageChange=async (pagination)=>{
    const ops={
      companyId:selectedCompany,
      subcompanyName:subcompanyName,
      pageSize:pagination.pageSize,
      pageNum:pagination.current
    }
  setLoading(true)
  const res = await reqGetCompanyList(ops)
  setLoading(false)
  if (res.success) {
    setCompanyList(res.data.subcompanyList)
    setPage(page=>({pageSize:pagination.pageSize,pageNum:pagination.current ,total:res.data.totalCount}))
  }
}
  return <div className="company-list">
    <AssignNumAdd visible={showAssignNumAdd} onCancel={closeAssignNumAdd} onSubmit={assignNumAdd} currentEdit={currentEdit} />
    <RollBackNumAdd visible={showRollBackNumAdd} onCancel={closeRollBackNumAdd} onSubmit={rollBackNumAdd} currentEdit={currentEdit} />
    <AssignCard visible={showAssignCard} onCancel={closeAssignCard} onSubmit={assignCard} currentEdit={currentEdit} />
    <EditSubCompany visible={showEditSubCompany} onCancel={closeEditSubCompany} onSubmit={editSubCompany} currentEdit={currentEdit} />
    <div className="company-list-search">
      <Form
        onFinish={onSearch}
      >
        <Row gutter={10}>
          <Col lg={6}>
            <Form.Item
              label="上级公司"
              name="companyId"
            >
              <Select
                allowClear={true}
                showSearch
                filterOption={(input, option) =>
                  option.children.indexOf(input) >= 0
                }
                placeholder="选择所属公司">
                {
                  <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                }
                {
                  subCompanys.map(item => {
                    //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                    return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} >
            <Form.Item
              label="公司名称"
              name="subcompanyName"
            >
              <Input placeholder="输入子公司名称搜索" />
            </Form.Item>
          </Col>
          <Col lg={4} offset={6}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button htmlType="button" htmlType="reset">
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
    <div className="company-list-table">
      <Table columns={columns}
        size="small"
        rowKey="companyId"
        loading={loading}
        onChange={onPageChange}
        pagination={{
          defaultPageSize: 30,
          total: page.total,
          current: page.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          position: ["bottomCenter"],
          pageSizeOptions: [10, 30, 50, 100],
          showTotal: total => `总记录： ${total} 条`
        }}
        dataSource={companyList}
        bordered
      >
      </Table>
    </div>
  </div>
}
export default CompanyList