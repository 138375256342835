import store from "store"
export default {
    saveCompanyInfo: (options) => {
        const oldOptions = store.get("company_info")
        store.set("company_info", {...oldOptions,...options})
    },
    getCompanyInfo: () => {
        return store.get("company_info")
    },
    clear: () => {
        store.clearAll()
    }
}