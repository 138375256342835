import React, { useState } from 'react';
import { Radio, Modal, Form, Select, TreeSelect } from 'antd';
import { connect } from 'react-redux';
const { Option } = Select
const { TreeNode } = TreeSelect;
const BatchEditCompany = props => {
    const { visible, onCancel, onSubmit, companies } = props
    const [form] = Form.useForm();
    const [currentCompany, subCompanys] = companies
    return (
        <Modal
            open={visible}
            title="请选择公司"
            okText="确定"
            cancelText="取消"
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="inline"
                name="form_in_modal"
                initialValues={{ company_id: currentCompany?.company_id }}
            >
                <Form.Item
                    name="company_id"
                    label="公司"
                    rules={[{ required: true, message: '请选择要分配的公司' }]}
                >
                    <Select style={{ width: 200 }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.indexOf(input) >= 0
                        }
                        placeholder="请选择"
                    >
                        {currentCompany && <Option key={currentCompany.company_id} value={currentCompany.company_id} >{currentCompany.company_name}</Option>}
                        {

                            subCompanys && subCompanys.map(item => {
                                return <Option key={item.company_id} value={item.company_id} >{item.company_name}</Option>
                            })
                        }

                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
}
export default connect(
    state => ({ companies: state.companies })
)(BatchEditCompany)