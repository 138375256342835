import { Modal, Form, Input, InputNumber } from "antd";
import React, { Component } from "react";

class EditPosition extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    formRef = React.createRef()
    onCancel = () => {
        this.props.onCancel()
    }
    onOk = e => {
        this.formRef.current
            .validateFields()
            .then(values => {
                this.props.onSubmit(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }
    render() {
        return (<Modal
            open={this.props.visible}
            title="设置位置服务地址"
            onCancel={this.onCancel}
            onOk={this.onOk}
        >
            <Form
                ref={this.formRef}
            >
                <Form.Item
                    name="server"
                    label="地址"
                    rules={[
                        {
                            required: true,
                            message: "请填写地址"
                        }, {
                            pattern: /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]?|[1-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]?|[1-9][0-9]?)$|^(?=^.{3,255}$)([a-zA-Z0-9][-a-zA-Z0-9]{0,62})?(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})*\.[a-zA-Z]{1,62}$/,
                            message: "地址格式不正确"
                        }
                    ]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name="port"
                    label="端口"
                    rules={[
                        { required: true, message: "请填写端口" },
                        {
                            validator: async (rule, value) => {
                                if (value < 1 || value > 65535)
                                    throw new Error('端口号不合法!');
                            }
                        }
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name="interval"
                    label="间隔"
                    rules={[
                        { required: true, message: "请填写上传间隔" },
                        {
                            validator: async (rule, value) => {
                                if (value < 5 || value > 240)
                                    throw new Error('定位间隔5~240秒!');
                            }
                        }
                    ]}
                >
                    <InputNumber prefix="秒" />
                </Form.Item>
            </Form>
        </Modal>)
    }
}

export default EditPosition