import { useState, useEffect } from "react";
import { Table, message } from "antd"
import SearchBar from "./search-bar"
import { reqGetUserCardLogs } from "../../../api"
import moment from "moment";
import "./card-log.css"
const CardLog = () => {
    const [options, setOptions] = useState()
    //分页状态
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ current: 1, pageSize: 30 });
    const [tableLoading, setTableLoading] = useState()
    const [data, setData] = useState([])
    const [currentEdit, setCurrentEdit] = useState({})
    useEffect(() => {
        const getUserCardLogs = async () => {
            const { current, pageSize } = page
            setTableLoading(true)
            const res = await reqGetUserCardLogs({ ...options, page_size: pageSize, page_index: current })
            setTableLoading(false)
            if (res.code === 200) {
                //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
               const  list= res.data.list.map((item,index)=>{
                    item.index=index
                    return item
                })
                setTotal(res.data.row_count);
                setData(list)
            } else {
                message.error("获取列表失败")
            }

        }
        getUserCardLogs()
    }, [page, options])
    const onSearch = async (options) => {
        const range = options.range_time
        if (range) {
            options.start_time = moment(new Date(range[0])).format('{YYYY}/{Mo}/{DD} {H}:{mm}:{ss}')
            options.end_time = moment(new Date(range[1])).format('{YYYY}/{Mo}/{DD} {H}:{mm}:{ss}')
        }
        delete options.range_time
        //搜索
        const { current, pageSize } = page
        // const res = await reqAccountList({ ...options, page_size: pageSize, page_index: 1 })
        setOptions(options)
        setPage({ current: 1, pageSize })
        // setTotal(res.data.row_count);
        // setData(res.data.user_list)
    }
    const columns = [
        {
            title: '时间'
            , dataIndex: 'time',
            align: 'center'
        }, {
            title: '公司名称'
            , dataIndex: 'company_name',
            align: 'center'
        },
        {
            title: '公司账号'
            , dataIndex: 'company_account',
            align: 'center'
        }, {
            title: '用户账号'
            , dataIndex: 'user_account',
            align: 'center'
        },
        {
            // 可选，操作类型：0所有，1 划出, 2收入,3消耗
            title: '操作类型'
            , dataIndex: 'op_type',
            align: 'center'
            , render: value => {
                if (value === 1) {
                    return <span>划出</span>
                } else if (value === 2) {
                    return <span>收入</span>
                } else if (value === 3) {
                    return <span>消耗</span>
                } else if (value === 4) {
                    return <span>回收</span>
                }
            }
        },
        {
            title: '卡类型'
            , dataIndex: 'user_card_type',
            align: 'center'
            , render: value => {
                if (value === 0) {
                    return <span>可添加用户数</span>
                }
            }
        },
        {
            title: '数量'
            , dataIndex: 'num_change',
            align: 'center'
        },
        {
            title: '操作IP'
            , dataIndex: 'ip',
            align: 'center'
        }
    ]
    const handleTableChange = (page, filters, sorter) => {
        setPage(page)
    }
    return (
        <div className="card-log">
            <div className="card-log-search">
                <SearchBar onSearch={onSearch} />
            </div>
            <div className="card-log-table">
                <Table
                    size="small"
                    columns={columns}
                    rowKey="index"
                    dataSource={data}
                    pagination={{ total: total, defaultPageSize: page.pageSize, pageSizeOptions: [10, 30, 50, 100], current: page.current, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    bordered
                >
                </Table>
            </div>
        </div>
    )
}
export default CardLog