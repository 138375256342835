import React, { useState } from 'react';
import { Input, Modal, Form, Select, TreeSelect } from 'antd';

const BatchEditAccountPwd = ({ visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            open={visible}
            title="密码"
            okText="确定"
            cancelText="取消"
            onCancel={()=>{
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values,form);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="inline"
                name="form_in_modal"
                initialValues={{ pwd: "1" }}
            >
                <Form.Item
                    name="pwd"
                    label="密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="请输入密码" />
                </Form.Item>

            </Form>
        </Modal>
    )
}
export default BatchEditAccountPwd