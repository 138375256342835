import { Tabs, List } from "antd"
import BasicSetting from "./basic-setting"
import PosterSetting from "./poster-setting"
import "./index.css"
const { TabPane } = Tabs;
const { Item } = List
const SystemSettings = props => {
    return <div className="system-settings">
        <Tabs
            tabPosition="left"
            defaultActiveKey="1" >
            <TabPane tab="登录设置" key="1">
                <BasicSetting/>
            </TabPane>
            <TabPane tab="公司海报" key="2">
                <PosterSetting/>
             </TabPane>
        </Tabs>
    </div>
}
export default SystemSettings