import React from "react"
import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    DatePicker,
    Checkbox,
    Switch,
    Button,
    Transfer,
    message,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import store from "../../../../utils/storeUtils"
import { reqAddAccount } from "../../../../api"
import { connect } from "react-redux";
const { Option } = Select
const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
        disabled: i % 3 < 1,
    });
}
class SingleAdd extends React.Component {
    state = {
        isParent: false  //是否是总公司  决定是否显示到期时间得选择

    }
    // const [form] = Form.useForm()
    formRef = React.createRef()
    //准备前缀
    componentDidMount() {
        this.setPrefix(this.props.prefix)
        this.checkIsParent()
    }
    checkIsParent = () => {
        if (store.getCompanyInfo()?.parent === 0) {
            this.setState({
                isParent: true
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const prefix = nextProps.prefix
        this.setPrefix(prefix)
    }

    //设置前缀
    setPrefix = (prefix) => {
        this.formRef.current.setFieldsValue({ prefix })
    }
    // useEffect(() => {
    //     form.setFieldsValue({ prefix })  //给表单设置默认值 这里不能使用initialValues 因为初始化时props.prefix的值可能为空值
    // }, [prefix])
    tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 6,
            },
        },
    };
    formItemLayout = {
        labelCol: {
            xs: {
                span: 12,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 12,
            },
            sm: {
                span: 8,
            },
        },
    };
    handleSubmit = async values => {
        values.service_end_time = values.service_end_time?.format('YYYY-MM-DD HH:mm:ss')
        const group_ids = values.group_ids?.join(',')
        const res = await reqAddAccount({ ...values, group_ids })
        if (res.data?.result === 1) {
            message.success('添加成功')
        } else {
            message.warning(res.data?.err_msg)
        }
    }
    render() {
        const { formItemLayout, tailFormItemLayout, handleSubmit } = this
        const { groups } = this.props
        return (
            <Form
                {...formItemLayout}
                ref={this.formRef}
                name="register"
                initialValues={{
                    account_model: "2",
                    pwd: "1",
                    confirm_pwd: "1",
                    temp_call: true,
                    position: true
                }}
                scrollToFirstError
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="prefix"
                    label="账号前缀："
                    rules={[
                        {
                            required: true,
                            message: '未获取到前缀！',
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="account_model"
                    label="模式"
                    rules={[
                        {
                            required: true,
                            message: '请选择账号模式',
                        },
                    ]}
                >
                    <Select >
                        <Option value="2">ICCID</Option>
                        <Option value="1">自定义</Option>
                        <Option value="3">IMEI</Option>
                        <Option value="4">手机号</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="user_account"
                    label="登录账号："
                    dependencies={['account_model']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '必须填写登录账号',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const mode = getFieldValue('account_model')
                                switch (mode) {
                                    case "2":
                                        return /^\w{19,20}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在19~20位之间")
                                    case "1":
                                        return /^\w{2,7}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在2~7之间")
                                    case "3":
                                        return /^\w{15,15}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度为15位")
                                    case "4":
                                        return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value) ? Promise.resolve() : Promise.reject("账号必须未合法手机号")
                                    default:
                                        return Promise.resolve()
                                }
                            },
                        }),
                    ]}

                >
                    <Input placeholder="请输入账号" />
                </Form.Item>
                <Form.Item
                    name="user_name"
                    label="账号名称"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入账号名称!',
                            whitespace: true,
                        }, {
                            pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{1,9}$/,
                            message: '必需是数字、英文字母或中文，且长度在1~9位之间'
                        }
                    ]}
                >
                    <Input placeholder="请输入账号名称" />
                </Form.Item>
                <Form.Item
                    name="pwd"
                    label="密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="请输入密码" />
                </Form.Item>
                <Form.Item
                    name="confirm_pwd"
                    label="确认密码"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入确认密码!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('pwd') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('两次输入密码不一致!');
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="请输入确认密码" />
                </Form.Item>

                {
                    this.state.isParent ? (<Form.Item
                        name="service_end_time"
                        label="有效期"
                    >
                        <DatePicker />
                    </Form.Item>) : null

                }
                <Form.Item
                    name="temp_call"
                    label="单呼"
                    valuePropName="checked"
                >
                    <Switch></Switch>
                </Form.Item>
                {/* <Form.Item
                    name="position"
                    label="定位"
                    valuePropName="checked"
                >
                    <Switch></Switch>
                </Form.Item> */}
                <Form.Item name="group_ids"
                    label="群组">
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.children.indexOf(input) >= 0
                        }
                        mode="multiple"
                    >
                        {
                            groups.map(item => <Option key={item.group_id} >{item.group_name}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        确定
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}
export default connect(
    state => ({
        groups: state.groups
    })
)(SingleAdd)