
import { lazy, Suspense } from "react"
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AppLayout from "../pages/AppLayout/AppLayout"  //AppLayout 布局组件使用懒加载会造成加载闪屏
import { Navigate } from "react-router-dom";
import Home from '../pages/home'
import AccountList from '../pages/account/account-list'
import AccountAdd from '../pages/account/account-add'
import TerminalList from '../pages/account/terminal-list'
import GroupList from '../pages/group/group-list'
import GroupAdd from '../pages/group/group-add'
import GroupEdit from '../pages/group/group-edit'
import GroupMember from '../pages/group/group-member'
import CompanyList from '../pages/company/company-list'
import CompanyAdd from '../pages/company/company-add';
import CompanyRights from '../pages/company/company-rights';
import CardCreate from '../pages/card/card-create';
import SystemSettings from '../pages/system/system-settings';
import CardLog from '../pages/logs/log-card/card-log';
import AccountLog from '../pages/logs/logs-account/account-log';
import Login from '../pages/login/login';
import OrderList from '../pages/order/List';

let router = [{
    path: "/",
    element: <AppLayout />,
    children: [
        { index: true, element: <Navigate to="/home" />, },
        { path: "/home", element: <Home />, roles: ['admin'] },
        {
            path: "/account",
            children: [
                { path: "/account/list", element: <AccountList /> },
                { path: "/account/add", element: <AccountAdd /> },
                { path: "/account/terminallist", element: <TerminalList /> },
            ]
        },
        {
            path: "/group",
            children: [
                { path: "/group/list", element: <GroupList /> },
                { path: "/group/add", element: <GroupAdd /> },
                { path: "/group/edit/:group_id/:company_id", element: <GroupEdit /> },
                { path: "/group/member/:group_id", element: <GroupMember /> }
            ]
        },
        {
            path: "/company",
            children: [
                { path: "/company/list", element: <CompanyList /> },
                { path: "/company/add", element: <CompanyAdd /> },
                { path: "/company/rights", element: <CompanyRights /> },
            ]
        }
        , {
            path: "/card",
            children: [
                { path: "/card/create", element: <CardCreate /> },
            ]
        }
        , {
            path: "/system",
            children: [
                { path: "/system/settings", element: <SystemSettings /> },
            ]
        }
        , {
            path: "/logs",
            children: [
                { path: "/logs/card", element: <CardLog /> },
                { path: "/logs/account", element: <AccountLog /> },
            ]
        }, {
            path: "order",
            children: [
                { path: "/order/list", element: <OrderList /> },
            ]
        }, {
            path: "order",
            children: [
                { path: "/order/list", element: <OrderList /> },
            ]
        }

    ]
},
{ path: "/login", element: <Login /> }
]


export default router