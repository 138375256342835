//分配可添加用户数
import { useEffect } from "react"
import { Form, Modal, Input, Statistic, InputNumber } from "antd"
import { SwapRightOutlined } from "@ant-design/icons"
import { } from "../../../../api"
const AssignNumAdd = ({ visible, onCancel, onSubmit, currentEdit }) => {
    const [form] = Form.useForm()
    return (
        <Modal
            open={visible}
            title={<span>分配可添加用户数<SwapRightOutlined />{currentEdit.companyName}</span>}
            okText="分配"
            cancelText="取消"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{ assign_count: 1 }}

            >
                <Form.Item
                    name="assign_count"
                    label="分配数量"
                    rules={[
                        {
                            pattern: /^[1-9]{1}\d{0,3}$/,
                            message: '合法值：1~9999',
                        },
                    ]}
                >
                    <InputNumber min={1}></InputNumber>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AssignNumAdd