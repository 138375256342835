import { useState, useEffect } from "react"
import { Space, Table, message, Popconfirm, Button, Flex } from "antd"
import { useNavigate, Link } from "react-router-dom"
import EditSpeechSecond from "../components/edit-speech-second"
import EditGroupRemark from "../components/edit-group-remark"
import SearchBar from "../components/search-bar";
import "./index.css"
import { reqGetGroupList, reqEditSpeechSecond, reqDeleteGroup, reqEditGroupRemark } from "../../../api"
import { useDispatch } from "react-redux";
import { delete_group_by_id } from "../../../redux/actions"
import moment from "moment"
const GroupList = props => {
    const navigate = useNavigate()
    const [options, setOptions] = useState({})
    //分页状态
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ current: 1, pageSize: 30 });
    const [tableLoading, setTableLoading] = useState()
    const [data, setData] = useState([])
    const [currentEdit, setCurrentEdit] = useState({})
    const [editRemarkVisible, setEditRemarkVisible] = useState(false)
    const dispatch = useDispatch()
    // 分页、排序、筛选变化时触发
    const handleTableChange = (page, filters, sorter) => {
        //改变state 会重新执行 useEffect副作用（发起异步请求获取UserList）
        const { field, order } = sorter

        //如果不排序 删除掉排序属性
        if (order === undefined) {
            delete options.order_by
            delete options.order_dir
        } else { //如果有排序 加上排序属性
            setOptions({ ...options, order_by: sorter.field, order_dir: sorter.order === 'ascend' ? 1 : 0 })
        }
        setPage(page)
        //分页状态变化 清空当前列表数据 否则可能会引起 total总数据长度 大于 pageSize
        //  Warning: [antd: Table] `dataSource` length is less than `pagination.total` but large than `pagination.pageSize`. Please make sure your config correct data with async mode. 
        setData([])
    }
    const onSearch = async (options) => {
        const range = options.range_time
        if (range) {

            options.start_creat_time = moment(new Date(range[0])).format('{YYYY}/{Mo}/{DD} {H}:{mm}:{ss}')
            options.end_creat_time = moment(new Date(range[1])).format('{YYYY}/{Mo}/{DD} {H}:{mm}:{ss}')
        }
        delete options.range_time
        //搜索
        const { current, pageSize } = page
        // const res = await reqAccountList({ ...options, page_size: pageSize, page_index: 1 })
        setOptions(options)
        setPage({ current: 1, pageSize })
        // setTotal(res.data.row_count);
        // setData(res.data.user_list)
    }
    const updataTable = async () => {
        const { current, pageSize } = page
        const res = await reqGetGroupList({ ...options, page_size: pageSize, page_index: current })
        //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
        setTotal(res.data.row_count);
        setData(res.data.user_list)
    }
    //编辑通话时长 是否可见
    const [editSpeechSecondVisible, setEditSpeechSecondVisible] = useState(false)
    //取消编辑通话时长
    const cancelEditSpeechSecond = () => {
        setEditSpeechSecondVisible(false)
    }
    //编辑通话时长
    const editSpeechSecond = async (values) => {

        const res = await reqEditSpeechSecond(currentEdit.group_id, values.second)
        if (res.data.result === 1) {
            setEditSpeechSecondVisible(false)
            //更新表格
            message.success("设置通话时长成功")
            updataTable()
        }
    }
    //确认删除群组
    const confirmDeleteGroup = async (record) => {
        const { group_id } = record
        const res = await reqDeleteGroup(group_id)
        if (res.data?.result === 1) {
            message.success("删除成功")
            debugger
            updataTable()
            //更新redux
            dispatch(delete_group_by_id(group_id))
        } else {
            message.error(res.data.err_msg)
        }
    }
    useEffect(() => {
        const getAccountList = async () => {
            const { current, pageSize } = page
            setTableLoading(true)
            const res = await reqGetGroupList({ ...options, page_size: pageSize, page_index: current })
            setTableLoading(false)
            if (res.code === 200) {
                //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
                setTotal(res.data.row_count);
                setData(res.data.user_list)
            } else {
                message.error("获取账号列表失败")
            }

        }
        getAccountList()
    }, [page, options])
    const columns = [
        {
            title: "群组名称",
            dataIndex: "group_name",
        }
        , {
            title: "通话时长(s)",
            dataIndex: "limit_sencond",
            sorter: true,

        },
        {
            title: "群组类型",
            dataIndex: "group_type",

            render: (value) => {
                switch (value) {
                    case 1:
                        return <span>普通群组</span>
                    case 2:
                        return <span>超级调度</span>
                    case 3:
                        return <span>关联组</span>
                    default:
                        return <span>临时工作组</span>
                }
            }
        },
        {
            title: "创建时间",
            dataIndex: "creat_time",
            sorter: true,
            responsive: ['lg']
        },
        {
            title: "所属公司",
            dataIndex: "company_name",
            responsive: ['md'],
        }
        ,
        {
            title: '备注',
            dataIndex: 'remark',
            responsive: ['md'],
            render: (value, record) => {
                return <Button type="link" onClick={() => { setEditRemarkVisible(true); setCurrentEdit(record) }}>{value ? value : "备注"}</Button>
            }
        },
        , {
            title: "操作",
            render: (record) => {
                return (
                    <Flex wrap gap="small">
                        <Button type="link" size="small" onClick={() => {
                            navigate(`/group/edit/${record.group_id}/${record.company_id}`)
                        }}>修改</Button>
                        <Button type="link" size="small"
                            onClick={() => {
                                navigate(`/group/member/${record.group_id}`)
                            }}
                        >成员</Button>
                        <Button type="link" size="small"
                            onClick={() => {
                                setCurrentEdit(record)
                                setEditSpeechSecondVisible(true)
                            }}
                        >通话时长</Button>
                        <Popconfirm
                            title="群组删除后不可恢复"
                            onConfirm={() => { confirmDeleteGroup(record) }}
                            okText="删除"
                            cancelText="取消"
                        >
                            <Button type="link" size="small">删除</Button>
                        </Popconfirm>
                    </Flex>
                )
            }
        }
    ]
    const editRemark = async (values, form) => {
        const { group_id } = currentEdit
        let { remark } = values
        remark = remark || '备注'
        const res = await reqEditGroupRemark(group_id, remark)
        if (res.data.result === 1) {
            // setEMobileVisible(false)
            setEditRemarkVisible(false)
            message.success("修改备注成功")
            form.resetFields()
            //更新表格
            updataTable()
        } else {
            message.error(res.data.err_msg)
        }
    }
    const cancelEditRemark = () => {
        setEditRemarkVisible(false)
    }
    return (
        <div className="group-list">
            <EditSpeechSecond visible={editSpeechSecondVisible} onCancel={cancelEditSpeechSecond} onSubmit={editSpeechSecond} {...currentEdit}></EditSpeechSecond>
            <EditGroupRemark visible={editRemarkVisible} onCancel={cancelEditRemark} currentRemark={currentEdit.remark} onSubmit={editRemark}></EditGroupRemark>
            <div className="group-list-search">
                <SearchBar onSearch={onSearch}></SearchBar>
            </div>
            <div className="group-list-table">
                <Table columns={columns}
                    size="small"
                    dataSource={data}
                    rowKey="group_id"
                    onChange={handleTableChange}
                    pagination={{ total: total, defaultPageSize: page.pageSize, pageSizeOptions: [10, 30, 50, 100], current: page.current, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
                    loading={tableLoading}
                    bordered
                >
                </Table>
            </div>
        </div>
    )
}
export default GroupList