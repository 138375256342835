import { useEffect, useState, useMemo, useCallback } from 'react'
import store from "../../../utils/storeUtils"
import {
    reqAccountList
    , reqBatchRecharge
    , reqBatchEditCompany
    , reqBatchEditDefaultGroup
    , reqBatchEditAccountPwd
    , reqBatchDeleteAccount
    , reqDeleteAccount
    , reqEditTempCall
    , reqEditOpenPosition
    , reqEditMobile
    , reqEditPosition
    , reqEditRemark
    , reqResetAccount
    , reqQueryAccountByFile
} from "../../../api"
import SearchBar from "./search-bar"
import BatchActivate from "./batch-activate"
import BatchEditCompany from "./batch-edit-company"
import BatchEditDefaultGroup from "./batch-edit-default-group"
import BatchEditAccountPwd from "./batch-edit-account-pwd"
import BatchDeleteAccount from "./batch-delete-account"
import EditMobile from "./edit-mobile/edit-mobile"
import EditRemark from "./edit-remark"
import EditAccount from "./edit-account"
import FileDown from "../../../components/file-down/file-down.jsx"
import {
    TransactionOutlined
    , BranchesOutlined
    , TeamOutlined
    , CloseSquareOutlined
    , EyeInvisibleOutlined
    , EnvironmentOutlined
    , UploadOutlined
    , OrderedListOutlined
    , EditOutlined
    , FilterOutlined
} from '@ant-design/icons';
import {
    Button
    , Form
    , Table
    , Space
    , message
    , Popconfirm
    , Upload
    , Col
    , Popover
    , Checkbox
    , Row
    , Flex
    , theme
} from 'antd';
import dayjs from 'dayjs'
import "./index.css"
import BatchOutUnicom from './batch-out-unicom/index.jsx'

const AccountList = (props) => {
    const [currentEditAccountInfo, setCurrentEditAccountInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([])
    const [rowCount, setRowCount] = useState(0);
    const [options, setOptions] = useState({
        pageNum: 1,
        pageSize: 30,
    });
    const [editMobileVisible, setEditMobileVisible] = useState(false);
    const [editRemarkVisible, setEditRemarkVisible] = useState(false);
    const [batchActivateVisible, setBatchActivateVisible] = useState(false);
    const [editPositionVisible, setEditPositionVisible] = useState(false);
    const [outUnicomOpen, setOutUnicomOpen] = useState(false);

    const [editAccountVisible, setEditAccountVisible] = useState(false);
    const [editCompanyVisible, setEditCompanyVisible] = useState(false);
    const [editDefaultGroupVisible, setEditDefaultGroupVisible] = useState(false);
    const [editUserPwdVisible, setEditUserPwdVisible] = useState(false);
    const [deleteAccountVisible, setDeleteAccountVisible] = useState(false);
    const [showColums, setShowColumns] = useState(['serviceBeginTime']);
    const [fileList, setFileList] = useState([])
    const {
        token: { colorBgContainer }
    } = theme.useToken()
    useEffect(() => {
        getAccountList()
    }, [])
    const getAccountList = async (curOpts = {}) => {
        setTableLoading(true);
        const res = await reqAccountList(curOpts)
        if (res.success) {
            //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
            setTableLoading(false)
            setRowCount(res.data.rowCount);
            setData(res.data.list);
        } else {
            message.error("获取账号列表失败")
        }
    }
    const columns = [
        //  Table.SELECTION_COLUMN,
        {
            title: '登录账号',
            dataIndex: 'userAccount',
        },
        {
            title: '账号名称',
            dataIndex: 'name',
            responsive: ['sm'],
        },
        {
            title: 'ICCID',
            dataIndex: 'iccid',
        },
        {
            title: 'IMEI',
            dataIndex: 'imei',
        },
        {
            title: '所属集团',
            dataIndex: 'companyName',
            responsive: ['md'],
        },
        {
            title: '默认群组',
            dataIndex: 'defaultGroup',
            responsive: ['sm'],
        },
        {
            title: '状态',
            dataIndex: 'userEnable',
            responsive: ['md'],
            render: value => {
                switch (value) {
                    case 0: return <span>未激活</span>
                    case 1: return <span>可用</span>
                    case 2: return <span>暂停</span>
                    case 3: return <span>禁言</span>
                    default: return <span>停用</span>
                }
            }
        },
        {
            title: '创建时间',
            dataIndex: 'userCreateTime',
        },
        {

            title: '启用时间',
            dataIndex: 'serviceBeginTime',
            responsive: ['lg'],

        },
        {
            title: '到期时间',
            dataIndex: 'serviceEndTime',

            sorter: true,
            render: (value) => {
                if (new Date(value).getTime() < new Date().getTime()) {
                    return <span style={{ color: 'red' }}>{value}</span>
                } else {
                    return <span>{value}</span>
                }
            }
        },
        {
            title: '定位到期',
            dataIndex: 'locationEndTime',
            responsive: ['sm'],
            sorter: true,
            render: (value) => {
                if (new Date(value).getTime() < new Date().getTime()) {
                    return <div className="endTimeDiv"><span>{value}</span></div>
                } else {
                    return <div><span>{value}</span></div>
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            render: (value, record) => {
                return <Button type="link" onClick={() => {
                    setCurrentEditAccountInfo(record)
                    setEditRemarkVisible(true)
                }}>{value ? value : "备注"}</Button>
            }
        },
        {
            title: '操作',
            key: 'operation',
            render: (text, record) => {
                return (
                    <Flex wrap gap="small">
                        {
                            record.userEnable === 0 ? null : <Button type="link" size="small" onClick={() => {
                                setCurrentEditAccountInfo(record)
                                setEditAccountVisible(true)
                            }}>修改</Button>
                        }
                        <Popconfirm
                            title="账号删除后不可恢复"
                            onConfirm={() => { confirmDeleteSingleAccount(record) }}
                            okText="删除"
                            cancelText="取消"
                        >
                            <Button type="link" size="small">删除</Button>
                        </Popconfirm>
                    </Flex>
                )
            }
        },
    ]
    const editMobile = async (values, form) => {
        const { userId } = currentEditAccountInfo
        const { mobile } = values
        const res = await reqEditMobile(userId, mobile)
        if (res.data.result === 1) {
            setEditMobileVisible(false)
            message.success("修改成功")
            form.resetFields()
            //更新表格
            updataTable()
        } else {
            message.error(res.data.err_msg)
        }
    }
    const editRemark = async (values, form) => {
        const { userId } = currentEditAccountInfo
        let { remark } = values
        remark = remark || "备注"
        const res = await reqEditRemark(userId, remark)
        if (res.data.result === 1) {

            setEditRemarkVisible(false)
            message.success("修改备注成功")
            form.resetFields()
            //更新表格
            updataTable()
        } else {
            message.error(res.data.err_msg)
        }
    }
    //批量激活
    const activate = async (values, form) => {
        const res = await reqBatchRecharge(selectedRowKeys, values.serviceType, values.cardType)
        if (res.success) {
            setBatchActivateVisible(false)
            message.success("激活成功")
            form.resetFields()
            //更新表格
            updataTable()
        } else {
            message.error(res.msg)
        }
        // console.log(values)
    }
    //批量分配
    const editCompany = async (values) => {
        const res = await reqBatchEditCompany(selectedRowKeys.join(","), values.company_id)
        if (res.data.result === 1) {
            // setEditCompanyVisible(false)

            setEditCompanyVisible(false)
            //更新表格
            message.success("分配成功")
            updataTable()
        }
    }
    //     //批量设置默认群组
    const editDefaultGroup = async (values) => {
        const res = await reqBatchEditDefaultGroup(selectedRowKeys.join(","), values.group_id)
        if (res.data.result === 1) {
            setEditDefaultGroupVisible(false)
            //更新表格
            message.success("设置默认群组成功")
            updataTable()
        }
    }
    //批量设置位置服务
    const editPosition = async (values) => {
        const options = {
            userIds: selectedRowKeys,
            ...values
        }
        const res = await reqEditPosition(options)
        if (res.data.result === 1) {
            setEditPositionVisible(false)
            //更新表格
            message.success("设置位置服务成功")
            updataTable()
        }
    }
    //     //批量设置账号密码
    const editUserPwd = async (values, form) => {
        const res = await reqBatchEditAccountPwd(selectedRowKeys.join(","), values.pwd)
        if (res.data.result === 1) {
            setEditUserPwdVisible(false)
            form.resetFields()
            //更新表格
            message.success("修改密码成功")
        }
    }
    //批量删除账号
    const deleteAccount = async (values) => {
        const res = await reqBatchDeleteAccount(selectedRowKeys.join(","), values.validate_code)
        if (res.data?.result === 1) {
            setDeleteAccountVisible(false)
            setSelectedRowKeys([])
            //更新表格
            message.success("删除成功")
            updataTable()
        } else {
            message.error("验证码不正确")
        }
    }
    //刷新表格
    const updataTable = async () => {
        if (fileList.length > 0) {
            reqImportFile(fileList[0], true)
        } else {
            getAccountList(options)
        }
    }

    //导入查询的刷新表格


    //删除单个账号
    const confirmDeleteSingleAccount = async (record) => {
        const { userId, companyId } = record
        const res = await reqDeleteAccount(userId, companyId)
        if (res.data.result === 1) {
            message.success("删除成功")
            updataTable()
        }
    }
    //设置单呼 
    const editTempCall = async (checked, value, record) => {
        //value：账号的当前单呼权限状态
        //checked 开关Swich改变后的状态
        //record 当前操作的行信息
        const { userId } = record
        const res = await reqEditTempCall(userId, checked)
        if (res.data.result === 1) {
            message.success("操作成功！")
        }
    }
    const editOpenPosition = async (checked, value, record) => {
        //value：账号的当前定位权限状态
        //checked 开关Swich改变后的状态
        //record 当前操作的行信息
        const { userId } = record
        const res = await reqEditOpenPosition(userId, checked)
        if (res.data.result === 1) {
            message.success("操作成功！")
        }
    }
    const onSearch = async (updatedOptions) => {
        const range = updatedOptions.range_time;
        if (range) {
            updatedOptions.endTimeStart = dayjs(new Date(range[0])).format('YYYY/MM/DD H:mm:ss');
            updatedOptions.endTimeEnd = dayjs(new Date(range[1])).format('YYYY/MM/DD H:mm:ss');
        }
        delete updatedOptions.range_time;
        const curOpts = { ...updatedOptions, pageNum: 1, pageSize: options.pageSize }
        if (fileList.length > 0) { //如果查询时候有导入查询的文件 清空
            setFileList([])
        }
        setOptions(curOpts);
        await getAccountList(curOpts)
    }
    //     // 分页、排序、筛选变化时触发
    const handleTableChange = (page, filters, sorter) => {
        const { current, pageSize } = page
        const { field, order } = sorter
        let updatedOptions = { ...options, pageNum: current, pageSize: pageSize };
        //如果不排序 删除掉排序属性
        if (order !== undefined) {
            updatedOptions = { ...updatedOptions, orderBy: field, orderDir: order === 'ascend' ? 1 : 0 }
        }
        setOptions(updatedOptions)
        if (fileList.length > 0) { //如果是导入查询不操作
            setSelectedRowKeys([])
        } else {
            getAccountList(updatedOptions)
        }
    }
    const onSelectChange = selectedRow => {
        setSelectedRowKeys(selectedRow)
    };
    const cancelEditCompany = () => {
        setEditCompanyVisible(false)
    }
    const cancelEditDefaultGroup = () => {
        setEditDefaultGroupVisible(false)
    }
    const cancelEditUserPwd = () => {
        setEditUserPwdVisible(false)
    }
    const cancelDeleteAccount = () => {
        setDeleteAccountVisible(false)
    }
    const cancelEditRemark = () => {
        setEditRemarkVisible(false)
    }
    const cancelEditPosition = () => {
        setEditPositionVisible(false)
    }
    const cancelActivate = () => {
        setBatchActivateVisible(false)
    }
    const cancelEditAccount = () => {
        setEditAccountVisible(false)
    }
    const cancelEditMobile = () => {
        setEditMobileVisible(false)
    }
    const columsOnChange = (checkedValues) => {
        setShowColumns(checkedValues)
    }
    const onRemove = (file) => {
        getAccountList({ ...options, pageNum: 1 })
    }
    const resetAccount = async () => {
        try {
            const res = await reqResetAccount(selectedRowKeys);
            console.log(res);
            if (res.success) {
                message.success("重置成功");
                updataTable();
            }
            return Promise.resolve(); // 成功时返回 resolved 的 Promise  
        } catch (error) {
            message.error("发生错误，请稍后重试");
            return Promise.reject(); // 发生错误时返回 rejected 的 Promise  
        }
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    const hasSelected = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys]);

    const realColumns = useMemo(() => {
        //求两个数组的差集
        const subSet = function (arr1, arr2) {
            var set1 = new Set(arr1);
            var set2 = new Set(arr2);

            var subset = [];
            for (let item of set1) {
                if (!set2.has(item)) {
                    subset.push(item);
                }
            }

            return subset;
        };
        const dfHideColumns = ['imei', 'iccid', 'location_server', 'serviceBeginTime', 'remark', 'userCreateTime'] //, 'mobile'
        const hideColumns = subSet(dfHideColumns, showColums)
        return columns.filter(column => {
            return hideColumns.findIndex(i => column?.dataIndex === i) === -1
        })
    }, [columns])

    const reqImportFile = async (file, isUpdata = false) => {
        const formData = new FormData()
        formData.append('file', file)
        setTableLoading(true)
        const res = await reqQueryAccountByFile(formData)
        setTableLoading(false)
        if (res.success) {
            setData(res.data.list)
            setRowCount(res.data.rowCount)
            isUpdata || setOptions(opts => ({
                ...opts,
                pageNum: 1
            }))
        } else {
            message.warning(res.msg)
        }
    }
    return (
        <div className="account-list">
            {/* 头部搜索部分 */}
            <SearchBar onSearch={onSearch}></SearchBar>
            {/* 批量激活模态框组件 */}
            <BatchActivate visible={batchActivateVisible} onCancel={cancelActivate} onSubmit={activate} ></BatchActivate>
            <EditMobile visible={editMobileVisible} onCancel={cancelEditMobile} onSubmit={editMobile}></EditMobile>
            <EditRemark visible={editRemarkVisible} onCancel={cancelEditRemark} currentRemark={currentEditAccountInfo.remark} onSubmit={editRemark}></EditRemark>
            <BatchEditCompany visible={editCompanyVisible} onCancel={cancelEditCompany} onSubmit={editCompany} ></BatchEditCompany>
            <BatchEditDefaultGroup visible={editDefaultGroupVisible} onCancel={cancelEditDefaultGroup} onSubmit={editDefaultGroup}></BatchEditDefaultGroup>
            <BatchEditAccountPwd visible={editUserPwdVisible} onCancel={cancelEditUserPwd} onSubmit={editUserPwd}></BatchEditAccountPwd>
            <BatchDeleteAccount visible={deleteAccountVisible} onCancel={cancelDeleteAccount} onSubmit={deleteAccount}></BatchDeleteAccount>
            <BatchOutUnicom open={outUnicomOpen} close={() => { setOutUnicomOpen(false) }} userIds={selectedRowKeys} />
            {Object.getOwnPropertyNames(currentEditAccountInfo).length !== 0 ? <EditAccount visible={editAccountVisible} updataAccountTable={updataTable} onCancel={cancelEditAccount} {...currentEditAccountInfo}></EditAccount> : null}
            <Flex vertical style={{ background: colorBgContainer }}>
                <Flex justify="space-between" align="center" style={{ padding: 5 }} >
                    <Flex align="center" gap="small" wrap >
                        <Button type="primary" icon={<TransactionOutlined />} disabled={!hasSelected} loading={loading} onClick={() => { setBatchActivateVisible(true) }}>
                            充值
                        </Button>
                        <Button type="primary" icon={<BranchesOutlined />} disabled={!hasSelected} loading={loading} onClick={() => { setEditCompanyVisible(true) }}>
                            分配
                        </Button>
                        <Button type="primary" icon={<TeamOutlined />} disabled={!hasSelected} loading={loading} onClick={() => { setEditDefaultGroupVisible(true) }}>
                            设置默认群组
                        </Button>
                        <Button type="primary" icon={<EyeInvisibleOutlined />} disabled={!hasSelected} loading={loading} onClick={() => { setEditUserPwdVisible(true) }}>
                            修改密码
                        </Button>
                        <Button type="primary" icon={<CloseSquareOutlined />} disabled={!hasSelected} loading={loading} onClick={() => { setDeleteAccountVisible(true) }}>
                            删除
                        </Button>
                        {
                            store.getCompanyInfo()?.parent === 0 && <Button type="primary" disabled={!hasSelected} onClick={() => {
                                setOutUnicomOpen(true)
                            }}>出库</Button>
                        }

                        <FileDown loading={loading} api_url="/api/user/accountListExport" options={options} text="导出">
                        </FileDown>
                        {
                            store.getCompanyInfo()?.parent === 0 && <Popconfirm
                                title="重置后不可恢复！"
                                onConfirm={resetAccount}
                            >
                                <Button type="primary" icon={<EditOutlined />} disabled={!hasSelected}>重置账号</Button>
                            </Popconfirm>
                        }
                        <Upload
                            // name="file"
                            // action="/api/user/accountListByUpload"
                            // onChange={onUploadChange}
                            onRemove={
                                (file) => {
                                    const index = fileList.indexOf(file);
                                    const newFileList = fileList.slice();
                                    newFileList.splice(index, 1);
                                    setFileList(newFileList);
                                    onRemove()
                                }
                            }
                            beforeUpload={(file) => {
                                setFileList([file]);
                                reqImportFile(file);
                                return false
                            }}
                            accept=".xlsx"
                            maxCount={1}
                            fileList={fileList}
                        // onRemove={onRemove}
                        >
                            <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>导入查询</Button>
                        </Upload>

                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `已选中 ${selectedRowKeys.length} 行` : ''}
                        </span>

                    </Flex>
                    <Popover
                        trigger="click"
                        placement="leftTop"
                        content={<Checkbox.Group
                            onChange={columsOnChange}
                            defaultValue={showColums}
                        >
                            <Row>
                                <Col span={8}>
                                    <Checkbox value="iccid">ICCID</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="imei">IEMI</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="serviceBeginTime">启用时间</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="userCreateTime">创建时间</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="remark">备注</Checkbox>
                                </Col>

                            </Row>
                        </Checkbox.Group>}
                    >
                        <Button className="btn_custom" type="text" icon={<FilterOutlined />}></Button>
                    </Popover>
                </Flex>
                <Table
                    rowSelection={rowSelection}
                    columns={realColumns}
                    dataSource={data}
                    size="small"
                    rowKey="userId"
                    pagination={{
                        total: rowCount,
                        defaultPageSize: 30,
                        pageSize: options.pageSize,
                        current: options.pageNum,
                        pageSizeOptions: [10, 30, 50, 100],
                        showQuickJumper: true,
                        position: ["bottomCenter"],
                        showTotal: total => `总记录： ${total} 条`,
                        showSizeChanger: true
                    }}
                    onChange={handleTableChange}
                    loading={tableLoading}
                    bordered
                >
                </Table>
            </Flex>

        </div >
    )

}

export default AccountList