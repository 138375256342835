const SITE={
    DISABLE_SPEEK:true,  //是否支持禁言功能
    TELEPHONE_OPERATOR:true, //是否支持接线员账号
    SUPER_DISPATCHER:true, //是否支持超级调度
    ASSOCIATED_GROUP:true,//是否支持关联群组，默认为true
    ACROSS_GROUP:true,//是否支持跨群组单呼
    PLAYBACK:true,//是否支持录音回放
    EMERGENCY_GROUP:true,//紧急指挥组
    TITLE:'对讲管理平台',
    VERSION:'v1.1.3'
}

export default SITE