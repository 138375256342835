import {createStore} from "redux"
import reducers from "./reducer"
 import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore, persistReducer } from 'redux-persist'
// import thunk from "redux-thunk"
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [] // navigation will not be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer,composeWithDevTools())
let persistor = persistStore(store)

export default  {store,persistor}