import React, { useEffect, useState } from 'react';
import { Drawer, Form, Col, Button, Row, Input, Select, Table, Card, Switch, message, DatePicker } from 'antd';
import {
  LockOutlined,
  UnlockOutlined
} from "@ant-design/icons"
import { reqGetAccountDetail, reqDeleteAccountGroup, reqEditAccount } from "../../../../api"
import store from "../../../../utils/storeUtils"
import dayjs from 'dayjs';
import SITE from "../../../../config/siteConfig"
import { connect, useSelector } from "react-redux";
const { Option } = Select
function EditAccount({ userId, companyId, visible, onCancel, updataAccountTable }) {
  const [form] = Form.useForm()
  const [lockAccount, setLockAccount] = useState(true)
  const [loading, setLoading] = useState(false)
  const [groupList, setGroupList] = useState([])
  const [currentCompany, subCompanys] = useSelector(state => state.companies)
  const groups = useSelector(state => state.groups)
  useEffect(() => {
    if (visible) {
      getDetail(userId, companyId)
    }
  }, [companyId, userId, visible])

  const getDetail = async (user_id, company_id) => {
    setLoading(true)
    const res = await reqGetAccountDetail(user_id, company_id)
    setLoading(false)
    setGroupList(res.data.group_list)

    const service_end_time = res.data.user_info.service_end_time
    res.data.user_info.service_end_time = dayjs(service_end_time)
    if (res.data.user_info.default_group === 0) {
      res.data.user_info.default_group = null
    }

    form.setFieldsValue(res.data.user_info)
    form.setFieldsValue({ company_id: company_id })
  }
  // const closeAddGrop = () => {
  //   this.setState({
  //     addGroupVisible: false
  //   })
  // }

  const columns = [
    { title: '群组名称', align: 'center', dataIndex: 'group_name' },
    { title: '所属公司', align: 'center', dataIndex: 'company_name' },
    { title: '账号名称', align: 'center', dataIndex: 'name' },
    {
      title: '优先级',
      dataIndex: 'priority',
      align: 'center',
      render: (value) => {
        if (value === 1) return <span>普通</span>
        else if (value === 2) return <span>中级</span>
        else if (value === 3) return <span>高级</span>
      }
    },
    {
      title: '操作',
      dataIndex: '',
      align: 'center',
      key: 'x',
      render: record => <Button type="link" onClick={() => { deleteAccountGroup(record) }}>删除</Button>,
    }
  ];
  //删除群组
  const deleteAccountGroup = async (record) => {
    // 1.删除行
    // 2.发送请求删除群组
    const res = await reqDeleteAccountGroup(userId, record.group_id)
    if (res.data?.result === 1) {
      //删除行
      const index = groupList.findIndex((item) => item.group_id === record.group_id);
      const newList = groupList.filter((item, i) => i !== index)
      setGroupList(newList)
      message.success("删除成功")
    }
  }
  //更新账号的群组列表
  const updateUserGroup = (group_id, name) => {
    const index = groupList.findIndex(group => {
      return group.group_id === group_id
    })
    if (index === -1) { //群组列表中没有添加的群组
      const index2 = groups.findIndex(group => {
        return group.group_id === group_id
      })

      const addGroup = groups[index2]
      addGroup.name = name
      addGroup.company_name = "未知"
      addGroup.priority = 1
      const newList = groupList.concat([addGroup])
      setGroupList(newList)
    }
  }
  //更新详情
  const updataDetail = async (values) => {
    const { group_id, name, default_group } = values
    if (group_id) { //如果操作添加了群组
      updateUserGroup(group_id, name)
    }
    updateUserGroup(default_group, name)
  }
  const editAccount = async () => {
    form.validateFields().then(
      async values => {
        const service_end_time = values.service_end_time?.format('YYYY-MM-DD HH:mm:ss')
        // 2.发起请求
        const res = await reqEditAccount({ user_id: userId, ...values, service_end_time })
        debugger
        // 3.更新账号详情
        if (res.data?.result === 1) {
          message.success("编辑成功")
          //更新账号列表
          updataAccountTable()
          updataDetail(values)
        } else {
          message.warning(res.data.err_msg)
        }
      }
    ).catch(errorInfo => { })
    // 1.获取表单数据

  }
  const changeLock = () => {
    setLockAccount(!lockAccount)
  }
  const onClose = () => {
    // form.resetFields()
    onCancel()
    setLockAccount(true)
  }
  // render() {
  //   const { changeLock, editAccount, onClose, columns, formRef } = this
  //   const { loading, group_list, lockAccount } = this.state
  //   const { visible, companies, groups } = this.props
  //   //  const {companies,groups} = this.props 
  //   const [currentCompany, subCompanys] = companies

  return (
    <Drawer
      title="编辑用户"
      placement="right"
      open={visible}
      onClose={onClose}
      width={720}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            取消
          </Button>
          <Button onClick={editAccount} type="primary">
            保存
          </Button>
        </div>
      }
    >
      <Form layout="vertical" form={form} hideRequiredMark >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="user_account"
              label="登录账号"
              rules={[
                { required: true, message: '' },
                {
                  pattern: /^\w{6,20}$/,
                  message: '必需是数字、英文字母或中文，且长度在1~9位之间'
                }
              ]}
            >
              <Input disabled={lockAccount} placeholder="登录账号" addonBefore={lockAccount ? <LockOutlined onDoubleClick={changeLock} /> : <UnlockOutlined onDoubleClick={changeLock} />} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="user_type"
              label="用户类型"
              rules={[{ required: true, message: '' }]}
            >
              <Select placeholder="">
                <Option value={0}>普通</Option>
                {SITE.TELEPHONE_OPERATOR ? <Option value={2}>接线员</Option> : null}
                <Option value={3}>调度员</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="company_id"
              label="所属公司"
              rules={[{ required: true, message: '请选择所属公司' }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.indexOf(input) >= 0
                }
                placeholder="请选择"
              >
                <Option key={currentCompany.company_id} value={currentCompany.company_id} >{currentCompany.company_name}</Option>
                {

                  subCompanys.map(item => {
                    return <Option key={item.company_id} value={item.company_id} >{item.company_name}</Option>
                  })
                }

              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="账号名称"
              rules={[
                { required: true, message: '请输入账号名称' },
                {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{1,9}$/,
                  message: '必需是数字、英文字母或中文，且长度在1~9位之间'
                }
              ]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="请输入账号名称"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="default_group"
              label="默认群组"
              rules={[
                {
                  required: true, message: '请选择默认群组'
                }]}
            >

              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.indexOf(input) >= 0
                }
                placeholder="请选择"
              >
                {

                  groups.map(item => {
                    return <Option key={item.group_id} value={item.group_id} >{item.group_name}</Option>
                  })
                }

              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="group_id"
              label="添加到群组"
            >

              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.indexOf(input) >= 0
                }
                placeholder="请选择"
                allowClear={true}
              >
                {

                  groups.map(item => {
                    return <Option key={item.group_id} value={item.group_id} >{item.group_name}</Option>
                  })
                }

              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="user_enable"
              label="状态"
              rules={[{ required: true, message: '' }]}
            >
              <Select placeholder="">
                <Option value={1}>可用</Option>
                <Option value={2}>暂停</Option>
                {SITE.DISABLE_SPEEK ? <Option value={3}>禁言</Option> : null}
              </Select>
            </Form.Item>
          </Col>
          {
            store.getCompanyInfo().parent === 0 ?
              <Col span={12}>
                <Form.Item
                  name="service_end_time"
                  label="到期时间"

                >
                  <DatePicker allowClear={false} showTime={true} />
                </Form.Item>
              </Col> : null
          }
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="temp_call"
              label="单呼"
              valuePropName="checked"
              rules={[{ required: true, message: '' }]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item
              name="open_position"
              label="定位"
              valuePropName="checked"
              rules={[{ required: true, message: '' }]}
            >
              <Switch />
            </Form.Item> */}
          </Col>
        </Row>
      </Form>
      <Card bordered
        title="群组列表"
        loading={loading}
      >
        <Table
          columns={columns}
          dataSource={groupList}
          rowKey="group_id"
          size="small"
          bordered
          pagination={{ pageSize: 10 }}>

        </Table>
      </Card>
    </Drawer >
  )
}



export default EditAccount