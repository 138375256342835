import { Table, Button, Form, Input, Row, Col, DatePicker, Space, Modal, InputNumber, message, Popconfirm } from "antd";
import { useEffect, useState, useRef } from "react";
import "./List.css"
import { reqUnicomOrderList, reqCreateUnicomOrder, reqDeleteAccount, reqDelteUnicomOrder } from "../../api";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
export default function OrderList() {
    const [dataSource, setDataSource] = useState([])
    const [page, setPage] = useState({ pageNum: 1, pageSize: 30 })
    const [ops, setOps] = useState({})
    const [total, setTotal] = useState(0)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const columns = [{
        title: "业务号码",
        dataIndex: "bizNum"
    }, {
        title: "城市",
        dataIndex: "city"
    }, {
        title: "受理系统",
        dataIndex: "acceptSystem"
    },
    {
        title: "规格名称",
        dataIndex: "specName"
    },
    {
        title: "产品线",
        dataIndex: "product"
    },
    {
        title: "产品系列",
        dataIndex: "productSeries"
    },
    {
        title: "订购数量",
        dataIndex: "quantity"
    },
    {
        title: "交付状态",
        dataIndex: "deliveryStatus",
        // render:text=><span>{text||"-"}</span>
    },
    {
        title: "开户日期",
        dataIndex: "openDate",
        render: (text) => <span>{text && dayjs(text).format("YYYY/MM/DD")}</span>,
    }, {
        title: "交付日期",
        dataIndex: "deliveryDate",
        render: (text) => <span>{text && dayjs(text).format("YYYY/MM/DD")}</span>,
    }, {
        title: "操作",
        render: (text, record, index) => {
            return <Popconfirm
                title="确定要删除吗？"
                onConfirm={async () => {
                    const res = await reqDelteUnicomOrder(record.id)
                    if (res.success) {
                        message.info("删除成功")
                        const newData = dataSource.toSpliced(index, 1)
                        setDataSource(newData)
                        setTotal(total - 1)
                    }
                }}
            >
                <Button type="link">删除</Button>
            </Popconfirm>
        }
    }]
    useEffect(() => {
        getList()
    }, [])
    useEffect(() => {
        getList()
    }, [page, ops])
    const handleTableChange = (pagination, filters, sorte) => {
        setPage({ pageNum: pagination.current, pageSize: pagination.pageSize })
    }
    const getList = async () => {
        const options = { ...ops, ...page }
        setLoading(true)
        const res = await reqUnicomOrderList(options)
        setLoading(false)
        if (res.success) {
            setDataSource(res.data.list)
            setTotal(res.data.totalCount)
        }
    }
    return <div className="order-list">
        <div className="order-list-search">
            <Form onFinish={(values) => {
                const ops = {
                    bizNum: values.bizNum,
                    city: values.city,
                    deliveryDateStart: values.deliveryDate && dayjs(values.deliveryDate[0]).format("YYYY/MM/DD"),
                    deliveryDateEnd: values.deliveryDate && dayjs(values.deliveryDate[1]).format("YYYY/MM/DD")
                }
                setOps(ops)
            }}>
                <Row gutter={16}>
                    <Col>
                        <Form.Item label="业务号码" name="bizNum">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="地市" name="city">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="交付时间" name="deliveryDate">
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">查询</Button>
                                <Button htmlType="reset" onClick={() => {
                                    setOps({})
                                    setPage({ ...page, pageNum: 1 })
                                }}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
        <div className="order-list-table">
            <div className="order-list-table-op">
                <Button type="primary" onClick={() => {
                    setAddModalOpen(true)
                }}>新增订单</Button>
            </div>
            <Table
                loading={loading}
                size="small"
                rowKey="id"
                columns={columns}
                dataSource={dataSource}
                onChange={handleTableChange}
                pagination={{ total: total, defaultPageSize: page.pageSize, pageSizeOptions: [10, 30, 50, 100], current: page.pageNum, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
            />
        </div>
        {/* 模态框 */}
        <Modal
            open={addModalOpen}
            title="新增订单"
            onOk={() => {
                form.validateFields().then(async values => {
                    const ops = { ...values, deliveryDate: dayjs(values.deliveryDate).format("YYYY/MM/DD") }
                    const res = await reqCreateUnicomOrder(ops)
                    if (res.success) {
                        message.success("添加成功")
                        setAddModalOpen(false)
                        getList()
                    }
                })
            }}
            onCancel={() => {
                setAddModalOpen(false)
            }}
            destroyOnClose={true}
        >
            <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                preserve={false}
                initialValues={{
                    deliveryDate: dayjs(new Date())
                }}
            >
                <Form.Item label="业务号码" name="bizNum" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="地市" name="city" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="交付日期" name="deliveryDate" rules={[{ required: true }]}>
                    <DatePicker />
                </Form.Item>
                <Form.Item label="数量" name="quantity" rules={[{ required: true }]}>
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    </div>
}