import { message } from "antd";
import store from '../utils/storeUtils';
import cookie from 'react-cookies';

const cArr = [];

// 构建查询字符串  
const buildQueryString = (params) => {
    return new URLSearchParams(params).toString();
};

// 处理请求头  
const getHeaders = (data) => {
    if (data instanceof FormData) {
        return {}; // FormData 不需要设置 Content-Type  
    }
    return { 'Content-Type': 'application/json' };
};

// 支持的请求方法  
const supportedMethods = ['GET', 'POST', 'PUT', 'DELETE'];

function ajax(url, data = {}, method = "GET") {
    const controller = new AbortController();
    const { signal } = controller;

    return new Promise(async (resolve, reject) => {
        // 移除空值  
        Object.keys(data).forEach(key => {
            if (data[key] === undefined || data[key] === null) {
                delete data[key];
            }
        });

        let promise;

        const fetchInit = {
            signal,
            method: method.toUpperCase(),
            headers: getHeaders(data),
        };

        if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
            if (data instanceof FormData) {
                promise = fetch(url, {
                    ...fetchInit,
                    body: data,
                });
            } else {
                fetchInit.body = JSON.stringify(data);
                promise = fetch(url, fetchInit);
            }
        } else if (method.toLowerCase() === "get") {
            const queryStr = buildQueryString(data);
            promise = fetch(`${url}?${queryStr}`);
        } else if (method.toLowerCase() === "delete") {
            promise = fetch(url, fetchInit);
        } else {
            reject(new Error(`不支持的请求方法: ${method}`));
            return;
        }

        try {
            cArr.push(controller); // 记录控制器以便后续取消请求  
            const response = await promise;

            if (!response.ok) {
                const errorMessage = await response.text(); // 获取错误信息  
                const error = new Error(`请求失败: ${response.status} ${errorMessage}`);
                if (response.status === 401) {
                    cArr.forEach(item => item.abort());
                    console.log("授权失效");
                    window.location.href = "/#/login"; // 这里跳转  
                    cookie.remove('.SCAUTH', { path: '/' });
                    store.clear();
                    reject(new Error("授权失效"));
                } else if (response.status === 403) {
                    message.warning("无权限！");
                    reject(new Error("无权限"));
                } else {
                    reject(error);
                }
                return;
            }

            const res = await response.json();
            resolve(res);
        } catch (error) {
            message.error("请求错误：" + error.message);
            reject(error); // 传递错误信息  
        }
    });
}

export default ajax;