import { useState } from 'react';
import {
    Form
    , Row
    , Col
    , Input
    , Button
    , DatePicker,
    Select
} from 'antd';
import "./index.css"
import { connect } from 'react-redux';
const { RangePicker } = DatePicker
const { Option } = Select
const SearchBar = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [currentCompany, subCompanys] = props.companies;

    const onFinish = (values) => {
        setLoading(true)
        //清楚空属性
        for (const key in values) {
            if (values[key]) {
                continue
            } else {
                delete values[key]
            }
        }
        //onSearch是async函数 n为返回的promist
        const resultPromise = props.onSearch(values)
        //如果promisi是成功状态说明查询已经完毕
        resultPromise.then(res => {
            setLoading(false)
        })
    };
    return (
        <div className="editlog-search-bar">
            <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{
                    op_type: "0"
                }}
            >
                <Row gutter={10}>
                    <Col span={6}>
                        <Form.Item
                            label="用户账号"
                            name="user_account"
                        >
                            <Input placeholder='登录账号'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="所属公司"
                            name="user_compnay_id"
                        >
                            <Select
                                allowClear={true}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.indexOf(input) >= 0
                                }
                                // onChange={onCompanychange}
                                placeholder="选择所属公司">
                                {
                                    <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                                }
                                {
                                    subCompanys.map(item => {
                                        //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                                        return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="操作类型"
                            name="op_type"
                        >
                            <Select>
                                <Option value="0">全部</Option>
                                <Option value="1">添加</Option>
                                <Option value="2">编辑</Option>
                                <Option value="3">删除</Option>
                                <Option value="4">批量添加</Option>
                                <Option value="5">批量导入</Option>
                                <Option value="9">重置</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="range_time" label="操作时间" >
                            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>

                    <Col
                        span={16}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button loading={loading} type="primary" htmlType="submit">
                            查询
                     </Button>
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            重置
             </Button>

                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default connect(
    state=>({
        groups:state.groups,
        companies:state.companies
    })
)(SearchBar) 