import { useEffect, useState } from 'react'
import { reqGetAccountType } from "../api"
export const useAccountTypes = () => {
    const [accountTypes, setAccountTypes] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const getAccounttype = async () => {
            setLoading(true)
            const res = await reqGetAccountType()
            setLoading(false)
            if (res.code === 200) {
                setAccountTypes(res.data.user_type_list)
            }
        }
        getAccounttype()
    }, [])

    return [accountTypes, loading]
}