import { Upload, message, Button, List, Modal, Input, Form } from "antd"
import { useState, useEffect } from "react"
import { reqGetPoster, reqSavePoster } from "../../../api"
import { LoadingOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import store from "../../../utils/storeUtils"
const PosterSetting = () => {
    const [imageUrl, setImageUrl] = useState(false)
    const [saveUrl, setSaveUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [companyName,setCompanyName]=useState("")
    const [phoneNum,setPhoneNum]=useState("")
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    useEffect(() => {
        (async function () {
            const res = await reqGetPoster()
            if (res.code === 200) {
                setImageUrl(res.data?.poster)
                setCompanyName(res.data?.company_name)
                setPhoneNum(res.data?.phone_num)
            }
        })()
    }, [])
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            setSaveUrl(info.file.response.data.file_url)
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl)
                setLoading(false)
            }
            );
        }
    };

    //
    const savePoster = async () => {
        const res = await reqSavePoster(saveUrl)
        if (res.data?.result === 1) {
            message.success("保存成功")
        }
    }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))

    };
    const handleCancel = () => setPreviewVisible(false);
    return (
        <div className="poster-setting">
            <div className="poster-setting-header">
                <h1>公司海报</h1>
            </div>
            <div className="poster-setting-content">
                <div className="left">
                    <Form
                        layout="vertical"
                    >
                        <Form.Item label="公司名称：">
                            <span>{companyName}</span>
                        </Form.Item>
                        <Form.Item label="联系电话：">
                            <span className="right">{phoneNum}</span>
                        </Form.Item>
                        <Form.Item label="海报：">
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="/api/setting/upload_poster"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                xs: {
                                    span: 24,
                                    offset: 0,
                                },
                                sm: {
                                    span: 16,
                                    offset: 0,
                                },
                            }}
                        >
                            <Button type="primary" onClick={savePoster}>
                                保存
                         </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="right">

                </div>
            </div>

        </div>
    )
}
export default PosterSetting