import { Row, Col, Select, Button, Input, Space, Form, message } from "antd"
import { reqAccountList } from "../../../api";
import { Table } from "antd";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
const columns = [
    {
        title: "账号",
        dataIndex: "userAccount",
        align:'center'
    }, {
        title: "名称",
        dataIndex: "name",
        align:'center'
    }, {
        title: "所属公司",
        dataIndex: "companyName",
        align:'center'
    }, {
        title: "状态",
        dataIndex: "userEnable",
        render: value => {
            if (value === 1) {
                return <span>可用</span>
            } else if (value === 2) {
                return <span>暂停</span>
            } else if (value === 3) {
                return <span>禁言</span>
            } else {
                return <span>未激活</span>
            }
        }
    }, {
        title: "到期时间",
        dataIndex: "serviceEndTime"
    }


]

const SelectMember = ({ onSubmit,companies,groups }) => {
    //选择公司
    const [currentCompany, subCompanys] = companies;
    //当前选择的公司
    let [selectedCompany, setSelectedCompany] = useState(-1);
    //选择群组
    const [form] = Form.useForm()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [options, setOptions] = useState({})
    const [page, setPage] = useState({ current: 1, pageSize: 30 })
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getAccountList = async () => {
            const { current, pageSize } = page
            setLoading(true)
            const res = await reqAccountList({ ...options, pageSize: pageSize, pageNum: current })
            setLoading(false)
            if (res.success) {
                //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
                setTotal(res.data.rowCount);
                setData(res.data.list)
            } else {
                message.error("获取账号列表失败")
            }

        }
        getAccountList()
    }, [page, options])
    const onChange = (keys) => {
        let arr = new Set([...selectedRowKeys, ...keys])  //set去重
         setSelectedRowKeys([...arr])
    }
    const onSearch = (values) => {
        const { current, pageSize } = page
        // const res = await reqAccountList({ ...options, page_size: pageSize, page_index: 1 })
        setOptions(values)
        setPage({ current: 1, pageSize })
    }
    const nextStep = () => {
        onSubmit(selectedRowKeys)
    }
    const onCompanychange = value => {
        setSelectedCompany(value)
    }
    // 分页、排序、筛选变化时触发
    const handleTableChange = page => {
        //改变state 会重新执行 useEffect副作用（发起异步请求获取UserList）
        setPage(page)
        //分页状态变化 清空当前列表数据 否则可能会引起 total总数据长度 大于 pageSize
        //  Warning: [antd: Table] `dataSource` length is less than `pagination.total` but large than `pagination.pageSize`. Please make sure your config correct data with async mode. 
        setData([])
    }
    const clearSelected = () => {
        setSelectedRowKeys([])
    }

    return (
        <div className="select-member">
            <div className="search-bar">
                <Form
                    form={form}
                    onFinish={onSearch}
                >
                    <Row gutter={10}>
                        <Col span={6}>
                            <Form.Item
                                label="终端号码"
                                name="terminalNum"
                            >
                                <Input placeholder="账号/ICCID/IMEI"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="号码段" style={{ marginBottom: 0 }}>
                                <Form.Item
                                    name="startNum"
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <Input placeholder="起始号码" />
                                </Form.Item>
                                <Form.Item
                                    name="endNum"
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                >
                                    <Input placeholder="结束号码" />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="账号名称"
                                name="name"
                            >
                                <Input placeholder="输入账号名称查询" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={6}>
                            <Form.Item
                                label="所属公司"
                                name="companyId"
                            >
                                <Select onChange={onCompanychange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.indexOf(input) >= 0
                                    }
                                    placeholder="选择所属公司">

                                    {
                                        <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                                    }
                                    {
                                        subCompanys.map(item => {
                                            //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                                            return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                label="所属群组"
                                name="groupId"
                            >
                                <Select
                                    filterOption={(input, option) =>
                                        option.children.indexOf(input) >= 0
                                    }
                                    showSearch
                                    placeholder="选择所属群组">

                                    {
                                        groups.map(item => {
                                            return <Select.Option key={item.group_id} value={item.group_id}>{item.group_name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        form.resetFields();
                                    }} >重置</Button>
                                    <Button htmlType="submit" >查询</Button>
                                    <Button type="primary" onClick={clearSelected}>清空已选</Button>
                                    <Button type="primary" onClick={nextStep}>下一步</Button>
                                    <span>已选中 {selectedRowKeys.length} 行</span>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </div>
            <div className="account-list-table">
                <Table
                    size="small"

                    rowSelection={{
                        selectedRowKeys,
                        onChange: onChange
                    }}
                    rowKey="userId"
                    columns={columns}
                    dataSource={data}
                    pagination={{ total: total, defaultPageSize: page.pageSize, pageSizeOptions: [10, 30, 50, 100], current: page.current, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
                    loading={loading}
                    bordered
                    onChange={handleTableChange}
                >

                </Table>
            </div>
        </div>

    )
}
export default connect(
    state=>({
        groups:state.groups
        ,companies:state.companies
    })
)(SelectMember)