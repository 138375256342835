
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import cookie from "react-cookies"
import "./login.css";
import { reqLogin } from "../../api"
import store from "../../utils/storeUtils"
import SITE from '../../config/siteConfig';
// import logo from "../../assets/images/logo.png"
import { useNavigate } from "react-router-dom"
import { useEffect } from 'react';
const Login = (props) => {
    const navigate = useNavigate()
    const onFinish = async (values) => {
        const { username, password } = values
        const response = await reqLogin(username, password)
        if (response.data.result === 1) {
            message.success("登录成功")
            navigate("/home")
            store.saveCompanyInfo({ parent: response.data.parent, company_account: username, company_name: response.data.company_name })
        } else {
            message.error(response.data.err_msg)
        }
    };

    useEffect(() => {
        //如果cookie已经存在 直接进入admin 实现自动登录
        if (cookie.load(".SCAUTH")) {
            // return <Redirect to="/"></Redirect>
            navigate("/home")
        }
    })

    return (
        <div className="login">
            <section className="login-box">
                <div className="logo">
                    {/* <img src={logo} /> */}
                    <h1>{SITE.TITLE}</h1>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '请输入用户名!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入账号" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入密码' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="请输入密码"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">登录 </Button>
                    </Form.Item>
                </Form>
            </section>
        </div>
    )
}
export default Login