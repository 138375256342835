//分配可添加用户数
import { useState, useEffect } from "react"
import { Form, Modal, Input, Card } from "antd"
import { reqGetCompanyList } from "../../../../api"
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const EditSubCompany = ({ visible, onCancel, onSubmit, currentEdit }) => {
  const [form] = Form.useForm()
  useEffect(() => {

    const { comUserAccount, companyName, phoneNum } = currentEdit
    form.setFieldsValue({
      name: companyName,
      // user_name:user_name,
      userAccount: comUserAccount,
      phoneNum: phoneNum
    })

  }, [currentEdit])
  return (
    <Modal
      open={visible}
      title="修改子公司"
      okText="修改"
      cancelText="取消"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            // form.resetFields();
            onSubmit(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >

      <Form
        form={form}
        {...formItemLayout}
        name="form_in_modal"
      >


        <Form.Item name="name" label="公司名称"
          rules={[{ required: true, message: '请输入公司名称!' }]}
        >
          <Input type="textarea" />
        </Form.Item>
        {/* <Form.Item name="user_name" label="姓名"
         rules={[{ required: true, message: '请输入姓名!' }]}
        >
          <Input type="textarea" />
        </Form.Item> */}
        <Form.Item name="phoneNum" label="电话"
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item name="userAccount" label="用户名"
          rules={[{ required: true, message: '加载中!' }]}
        >
          <Input disabled type="textarea" />
        </Form.Item>
        <Form.Item name="userPwd" label="密码"
          rules={[{ required: true, message: '请输入新密码!' }]}
        >
          <Input.Password type="textarea" />
        </Form.Item>
        <Form.Item
          name="confirmPwd"
          dependencies={["userPwd"]}
          label="确认密码"
          rules={[
            {
              required: true,
              message: '请输入确认密码!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('userPwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('两次输入密码不一致!');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>

    </Modal>
  )
}

export default EditSubCompany