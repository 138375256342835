import React from "react"
import { reqBatchAddAccount } from "../../../../api"
import store from "../../../../utils/storeUtils"
import {
    Form,
    Input,
    Select,
    DatePicker,
    Button,
    message,
} from 'antd';
// import {} from '@ant-design/icons';
const { Option } = Select
class BatchAdd extends React.Component {
    formRef = React.createRef()
    state = {
        isParent: false  //是否是总公司  决定是否显示到期时间得选择
    }
    componentDidMount() {
        this.setPrefix(this.props.prefix)
        this.checkIsParent()
    }
    //准备前缀
    UNSAFE_componentWillReceiveProps(nextProps) {
        const prefix = nextProps.prefix
        this.setPrefix(prefix)
    }
    //设置前缀
    setPrefix = (prefix) => {
        this.formRef.current.setFieldsValue({ prefix })
    }

    checkIsParent = () => {
        if (store.getCompanyInfo()?.parent === 0) {
            this.setState({
                isParent: true
            })
        }
    }
    tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 6,
            },
        },
    };
    formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },

    };
    handleSubmit = async values => {
        values.service_end_time = values.service_end_time?.format('YYYY-MM-DD HH:mm:ss')
        const res = await reqBatchAddAccount(values)
        if (res.data?.result === 1) {
            message.success("添加成功")
        } else {
            message.warning(res.data?.err_msg)
        }
    }
    render() {
        const { formItemLayout, handleSubmit, tailFormItemLayout } = this
        return (
            <Form
                {...formItemLayout}
                ref={this.formRef}
                name="register"
                initialValues={{
                    account_model: "2",
                    pwd: "1",
                    confirm_pwd: "1"
                }}
                scrollToFirstError
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="prefix"
                    label="账号前缀："
                    rules={[
                        {
                            required: true,
                            message: '未获取到前缀！',
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="account_model"
                    label="模式"
                    rules={[
                        {
                            required: true,
                            message: '请选择账号模式',
                        },
                    ]}
                >
                    <Select >
                        <Option value="2">ICCID</Option>
                        {/* <Option value="3">IMEI</Option> */}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="start_user_account"
                    label="起始账号"
                    dependencies={['account_model']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '必须填写起始账号',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const mode = getFieldValue('account_model')
                                switch (mode) {
                                    case "2":
                                        return /^\w{19,20}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在19~20位之间")
                                    case "1":
                                        return /^\w{2,7}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在2~7之间")
                                    case "3":
                                        return /^\w{7,20}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在7~20之间")
                                    default:
                                        return Promise.resolve()
                                }
                            },
                        }),
                    ]}

                >
                    <Input placeholder="请输入账号" />
                </Form.Item>
                <Form.Item
                    name="end_user_account"
                    label="结束账号"
                    dependencies={['account_model']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请选择账号模式',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const mode = getFieldValue('account_model')
                                switch (mode) {
                                    case "2":
                                        return /^\w{19,20}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在19~20位之间")
                                    case "1":
                                        return /^\w{2,7}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在2~7之间")
                                    case "3":
                                        return /^\w{7,20}$/.test(value) ? Promise.resolve() : Promise.reject("必需是英文字母或数字，且长度在7~20之间")
                                    default:
                                        return Promise.resolve()
                                }
                            },
                        }),
                    ]}

                >
                    <Input placeholder="请输入账号" />
                </Form.Item>
                <Form.Item
                    name="start_user_name"
                    label="起始账号名称"
                    hasFeedback
                    rules={[]}
                >
                    <Input placeholder="请输入账号名称" />
                </Form.Item>
                <Form.Item
                    name="pwd"
                    label="密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="请输入密码" />
                </Form.Item>
                <Form.Item
                    name="confirm_pwd"
                    label="确认密码"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入确认密码!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('pwd') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('两次输入密码不一致!');
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="请输入确认密码" />
                </Form.Item>
                {
                    this.state.isParent ? (<Form.Item
                        name="service_end_time"
                        label="有效期"
                    >
                        <DatePicker />
                    </Form.Item>) : null
                }
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        确定
                    </Button>
                </Form.Item>

            </Form>
        )
    }
}
export default BatchAdd