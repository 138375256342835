import { useState, useEffect, useRef } from "react"
import { Form, Input, Button, Row, Col, Table, Tag, Tree, Card, message } from "antd"
import {
    DownOutlined,
    FrownOutlined,
    SmileOutlined,
    MehOutlined,
    FrownFilled,
} from '@ant-design/icons';
import { reqGetSubCompanyRights, reqSaveRights } from "../../../api"
import Modal from "antd/lib/modal/Modal"
import store from "../../../utils/storeUtils"
import "./index.css"
const treeData = [
    {
        title: '权限列表',
        key: -1,
    },
];
const CompanyRights = props => {
    const [treeVisible, setTreeVisible] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [rights, setRights] = useState([])
    const [currentEdit, setCurrentEdit] = useState({})
    const [currentSelectedRights, setCurrentSelectedRights] = useState([])
    const [searchUserAccount, setSearchUserAccount] = useState("")
    const [options, setOptions] = useState({})
    useEffect(() => {
        const list = store.getCompanyInfo().rights.map(item => { return { title: item.right_name, key: item.right_id } })
        treeData[0].children = list
        setRights(treeData)
    }, [])
    useEffect(() => {
        (async function () {
            setLoading(true)
            const res = await reqGetSubCompanyRights()
            setLoading(false)
            if (res.code === 200) {
                setData(res.data?.user_list)
            }
        })()
    }, [])
    const openEditRights = (record) => {
        setCurrentEdit(record)
        setCurrentSelectedRights(record.rights)
        setTreeVisible(true)
    }
    const updataTable = async (user_account, company_name) => {

        const res = await reqGetSubCompanyRights(user_account, company_name)
        if (res.code === 200) {
            setData(res.data?.user_list)
        }
    }
    const onSearch = values => {
        setOptions(values)
        updataTable(values.user_account, values.company_name)
    }
    const editRights = async () => {
        currentSelectedRights.filter(item => item !== -1)

        const res = await reqSaveRights(currentEdit.user_id, currentSelectedRights.filter(item => item !== -1).join(","))

        if (res.data?.result === 1) {
            message.success("保存成功")
            setTreeVisible(false)
            updataTable(options.user_account, options.company_name)
        }
    }
    const columns = [
        {
            title: "公司账号",
            dataIndex: "user_account",
            align: 'center',
        },
        {
            title: "公司名称",
            dataIndex: "company_name",
            align: 'center',
        }
        , {
            title: "权限",
            align: 'center',
            render: (record) => {
                return record.rights.map(item => {
                    switch (item) {
                        case 1: return <Tag key={item} color="magenta">生成年卡</Tag>
                        default:
                            return;
                    }
                })
            }
        },
        {
            title: "操作",
            align: 'center',
            render: (record) => {
                return <Button type="link" onClick={() => { openEditRights(record) }}> 修改权限</Button >
            }
        }
    ]
    return <div className="company-rights">

        <div className="company-rights-search">
            <Form form={form} onFinish={onSearch}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="user_account"
                            label="公司账号"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="company_name"
                            label="公司名称"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        span={12}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button loading={loading} type="primary" htmlType="submit">
                            查询
                        </Button>
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            重置
                        </Button>

                    </Col>
                </Row>
            </Form>
        </div>
        <div className="comapny-rights-table">

            <Table
                loading={loading}
                size="small"
                columns={columns}
                rowKey="user_id"
                dataSource={data}
                bordered
                pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: [10, 30, 50, 100] }}
            >
            </Table>

        </div>
        <Modal
            width={300}
            height={1000}
            open={treeVisible}
            onCancel={() => { setTreeVisible(false) }}
            title="编辑权限"
            okText="保存"
            onOk={() => {
                editRights()
            }}
            cancelText="取消"
        >
            <Tree
                checkable
                defaultExpandAll={true}
                treeData={rights}
                checkedKeys={currentSelectedRights}
                onCheck={(checkedKeys) => {
                    setCurrentSelectedRights(checkedKeys)
                }}
            />,

        </Modal>
    </div>
}
export default CompanyRights