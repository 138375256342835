import { Card, List, Form, InputNumber, Button, message } from "antd";
import { useEffect, useState } from "react";
import store from "../../../utils/storeUtils";
import { reqGetCardsCount, reqCreateCard } from "../../../api";
import "./index.less"
import Modal from "antd/lib/modal/Modal";
import { CreditCardTwoTone } from "@ant-design/icons"
const CardCreate = props => {
    const [permCount, setPermCount] = useState(0)
    const [yearCount, setYearCount] = useState(0)
    const [monthCount, setMonthCount] = useState(0)
    const [locationMonthCard, setLocationMonthCard] = useState(0)
    const [locationYearCard, setLocationYearCard] = useState(0)
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [cardType, setCardType] = useState(-1)
    const [serviceType, setServiceType] = useState(-1)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        (async function () {
            setLoading(true)
            const res = await reqGetCardsCount()
            setLoading(false)
            if (res.success) {
                const { numAddUsers, monthCadrsCount, yearCadrsCount, permCadrsCount, locationMonthCard, locationYearCard } = res.data
                setPermCount(permCadrsCount)
                setYearCount(yearCadrsCount)
                setMonthCount(monthCadrsCount)
                setLocationYearCard(locationYearCard)
                setLocationMonthCard(locationMonthCard)
            }
        })()
    }, [])

    const createCard = (serviceType, cardType) => {
        setVisible(true)
        setCardType(cardType)
        setServiceType(serviceType)
    }
    const onSubmit = async (count) => {
        const res = await reqCreateCard(serviceType, cardType, count)
        if (res.success) {
            message.success("生成成功")
            if (serviceType === 1) { //语音卡
                if (cardType === 1) {
                    setPermCount(permCount + count)
                } else if (cardType === 2) {
                    setYearCount(yearCount + count)
                } else {
                    setMonthCount(monthCount + count)
                }
            } else { //定位卡
                if (cardType === 2) {
                    setLocationYearCard(locationYearCard + count)
                } else if (cardType === 3) {
                    setLocationMonthCard(locationMonthCard + count)
                }
            }
            setVisible(false)
            form.resetFields()

        } else {
            message.warning(res.msg)
        }
    }
    const onCancel = () => {
        setVisible(false);
        form.resetFields()
    }
    return <div className="card-create">
        <Modal
            title="请输入数量"
            open={visible}
            okText="生成"
            cancelText="取消"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values.card_count);

                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}

        >
            <Form
                form={form}
            >
                <Form.Item
                    name="card_count"
                    label="数量"
                    rules={[
                        {
                            pattern: /^[1-9]{1}\d{0,2}$/,
                            message: '合法值：1~999',
                        },
                    ]}
                >
                    <InputNumber></InputNumber>
                </Form.Item>
            </Form>
        </Modal>
        <Card
            title="充值卡"
        >
            <List
                loading={loading}
            >
                <List.Item
                    actions={[<Button type="link" onClick={() => { createCard(1, 1) }}>生成</Button>]}
                >
                    <List.Item.Meta
                        avatar={<CreditCardTwoTone twoToneColor="#FA031C" />}
                        title="永久卡"
                        description={"现有数量：" + permCount}
                    />
                </List.Item>
                <List.Item
                    actions={[<Button type="link" onClick={() => { createCard(1, 2) }}>生成</Button>]
                    }
                >
                    <List.Item.Meta
                        avatar={<CreditCardTwoTone twoToneColor="#F76208" />}
                        title="年卡"
                        description={"现有数量：" + yearCount}
                    />
                </List.Item >
                <List.Item
                    actions={[<Button type="link" onClick={() => { createCard(1, 3) }}>生成</Button>]
                    }
                >
                    <List.Item.Meta
                        avatar={<CreditCardTwoTone twoToneColor="#009AB0" />}
                        title="月卡"
                        description={"现有数量：" + monthCount}
                    />
                </List.Item >
                <List.Item
                    actions={[<Button type="link" onClick={() => { createCard(2, 2) }}>生成</Button>]
                    }
                >
                    <List.Item.Meta
                        avatar={<CreditCardTwoTone twoToneColor="#009AB0" />}
                        title="定位年卡"
                        description={"现有数量：" + locationYearCard}
                    />
                </List.Item >
                <List.Item
                    actions={[<Button type="link" onClick={() => { createCard(2, 3) }}> 生成</Button >]}
                >
                    <List.Item.Meta
                        avatar={<CreditCardTwoTone twoToneColor="#009AB0" />}
                        title="定位月卡"
                        description={"现有数量：" + locationMonthCard}
                    />
                </List.Item >
            </List >

        </Card >
    </div >
}
export default CardCreate