import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import xlsx from "../../../../assets/files/upload_user_acount.xlsx"
import "./index.less"
const { Dragger } = Upload
const UploadAdd = (props) => {
    const handleOnChange = (info) => {
        if (info.fileList.length > 1) {
            info.fileList.shift()
        }
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            const { code, data } = info.file.response
            if (code === 200) {
                if (data.result === 1) {
                    message.success(`${info.file.name}导入成功`)
                } else {
                    message.error(data.err_msg)
                }
            }

        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    return (
        <div className="upload-add">
            <a href={xlsx} download="">下载模板</a>
            <Dragger
                name='file'
                action='/api/user/upload_account'
                onChange={handleOnChange}
                accept='.xlsx'
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p className="ant-upload-hint">
                    Support for a single upload. Strictly prohibit from uploading company data or other
                    band files
                 </p>
            </Dragger>
        </div>
    )
}

export default UploadAdd