//批量激活模态框组件
import React, { useState } from 'react';
import { Radio, Modal, Form, Select } from 'antd';
const BatchActivate = ({ visible, onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    function onCardTypeChange({ voice_card, gps_card }) {
        // console.log(requiredMarkValue)
        // let values
        // if(voice_card){
        //     values.voice_card=
        // }
        // // debugger
        // console.log(requiredMarkValue)
        form.setFieldsValue({ voice_card, gps_card })
    }
    return (
        <Modal
            open={visible}
            title="请选择激活卡类型"
            okText="确定"
            cancelText="取消"
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        let pram={};
                        pram.serviceType =values.voice_card?1:2
                        pram.cardType = values.voice_card||values.gps_card
                        onSubmit(pram,form);

                        // console.log(pram)
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                name="form_in_modal"
                initialValues={{ voice_card: 1 }}
                onValuesChange={onCardTypeChange}
            >
                <Form.Item
                    name="voice_card"
                    label="语音"
                >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={1}>永久</Radio.Button>
                        <Radio.Button value={2}> 年卡</Radio.Button>
                        <Radio.Button value={3}>月卡</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="gps_card"
                    label="定位"
                >
                    <Radio.Group buttonStyle="solid" >
                        {/* <Radio.Button value="1">永久</Radio.Button> */}
                        <Radio.Button value={2}> 年卡</Radio.Button>
                        <Radio.Button value={3}>月卡</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default BatchActivate

