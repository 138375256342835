import { Card, Form, Input, Select, Transfer, message, Col, Button } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { reqEditCompany, reqEditGroupName, reqGetGroupName } from "../../../api";
import SITE from "../../../config/siteConfig"
import {
    SettingOutlined,
    ArrowLeftOutlined,
    EditOutlined,
    EllipsisOutlined
} from "@ant-design/icons"
import "./index.less"
import { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux";
const { Item } = Form
const { Option } = Select
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    }
}
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 6,
        },
    },
};
const GroupEdit = props => {
    const params = useParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    // const groups = props.groups
    const [loading, setLoading] = useState(false)
    // const history = useHistory()
    const { group_id, company_id } = params
    //是否可见
    const [tansferVisible, setTransferVisible] = useState(false)
    //已选中的关联群组
    const [selectedGroups, setSelectedGroups] = useState([])
    const onSelectedChange = (values) => {
        setSelectedGroups(values)
    }
    useEffect(() => {
        //获取群组信息
        const getGroupName = async () => {
            setLoading(true)
            const res = await reqGetGroupName(company_id, group_id)
            setLoading(false)
            if (res.code === 200) {
                //改变总条数total  展开 「...page」 以确保我们没有 「丢失」 pageSize 和 current
                const { group_name, group_type, union_group } = res.data
                form.setFieldsValue({ group_name, group_type })
                if (group_type === 3) {
                    setTransferVisible(true)
                    setSelectedGroups(union_group || [])
                }
            } else {
                message.error("获群组信息失败")
            }
        }
        getGroupName()
    }, [company_id, group_id])
    const title = (
        <>
            <Button type="link" onClick={() => { navigate(-1) }}>
                <ArrowLeftOutlined />
            </Button>
            <span style={{ marginLeft: 20 }}>修改群组</span>
        </>
    )
    const onTypeChange = value => {
        if (value === 3) {
            setTransferVisible(true)
        } else {
            setTransferVisible(false)
        }
    }
    const saveEdit = async () => {
        try {
            const values = await form.validateFields()
            const { group_name, group_type = 1 } = values
            // if(group_type==undefined){
            //     group_type=1
            // }
            const union_group = selectedGroups?.join(',')
            const options = {
                company_id, group_id, group_name, group_type
            }
            if (group_type === 3) {
                options.union_group = union_group
            }
            const res = await reqEditGroupName(options)
            if (res.code === 200) {
                if (res.data.result === 1) {
                    message.success('修改成功')
                } else {
                    message.error(res.data.err_msg)
                }
            } else {
                message.error('失败了')
            }
        }
        catch (error) {
            console.log(error)
        }


    }
    const groups = useMemo(() => {
        return props.groups.map(item => {
            item.key = item.group_id
            if (item.group_id == group_id) {
                item.disabled = true
            } else {
                item.disabled = false
            }
            return item
        })
    }, [props.groups, group_id])
    return (
        <div className="group-edit">
            <Card
                title={title}
                bordered={false}
                loading={loading}
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={saveEdit}
                >
                    <Item
                        label="群组名称"
                        name="group_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入账号名称!',
                                whitespace: true,
                            }, {
                                pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{1,8}$/,
                                message: '字母、汉字或数字，长度不能超过8位'
                            }
                        ]}
                    >
                        <Input />
                    </Item>
                    {
                        (SITE.SUPER_DISPATCHER || SITE.ASSOCIATED_GROUP) && (
                            <Item
                                label="群组类型"
                                name="group_type"
                            >
                                <Select onChange={onTypeChange}>
                                    <Option value={1}>普通群组</Option>
                                    {SITE.SUPER_DISPATCHER ? <Option value={2}>超级调度</Option> : null}
                                    {SITE.ASSOCIATED_GROUP ? <Option value={3}>关联组</Option> : null}
                                    {SITE.EMERGENCY_GROUP ? <Option value={4}>临时工作组</Option> : null}
                                </Select>
                            </Item>)
                    }
                    {
                        tansferVisible && SITE.ASSOCIATED_GROUP && (
                            <Item
                                label="关联"
                            >
                                <Transfer
                                    showSearch
                                    dataSource={groups}
                                    render={item => item.group_name}
                                    targetKeys={selectedGroups}
                                    onChange={onSelectedChange}
                                    titles={['群组列表', '关联列表']}
                                    // rowKey={(record) => record.group_id}
                                    pagination={true}
                                ></Transfer>
                            </Item>
                        )
                    }
                    <Item
                        {...tailFormItemLayout}
                    >
                        <Button type="primary" onClick={() => { saveEdit() }}>保存</Button>
                    </Item>
                </Form>
            </Card>
        </div>
    )
}
export default connect(
    state => ({
        groups: state.groups
    })
)(GroupEdit)