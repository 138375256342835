import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {
  DownloadOutlined
} from '@ant-design/icons';
// import User from '/utils/user';
// import times from '_TOOLS_/times';
// import styles from './index.less';


// const downFileBtn = styles.downFileBtn;

class FileDown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingStatus: true,
      buttonDisabled: false
    }
  }

  // 提取文件名称
  getFileName = (disposition) => {

  }
  //文件下载操作
  handleDownFile = (event, api_url, options) => {
    let fileName = "账号列表.xlsx"
    event.preventDefault();
    event.stopPropagation();
    //如果传了options 拼接到url上
    if (options !== undefined) {
      let queryArr = [];
      for (const key in options) {
        if (options[key]) {//如果有值再传
          queryArr.push(key + '=' + options[key])
        }
      }
      let queryStr = queryArr.join('&');
      api_url += ("?" + queryStr)
    }
    //开启loading 按钮置灰
    this.setState({
      loadingStatus: false,
      buttonDisabled: true,
    });
    fetch(api_url, {
      method: 'get',
      credentials: 'include',
    }).then((res) => {
      // console.log(res)
      const disposition = res.headers.get('content-disposition')
      const arr = disposition.split(';')
      const utf = arr[2].split('\'')[2]
      fileName = decodeURI(utf)
      return res.blob()
    }).then(res => {
      // 对blob对象进行处理
      const a = document.createElement('a');
      const body = document.querySelector('body');
      // 这里注意添加需要下载的文件后缀；
      a.download = fileName;
      a.href = window.URL.createObjectURL(res);
      a.style.display = 'none';
      body.appendChild(a);
      a.click();
      body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
      this.setState({
        loadingStatus: true,
        buttonDisabled: false,
      });

    }).catch((error) => {
      //关闭loading 按钮恢复正常
      this.setState({
        loadingStatus: false,
        buttonDisabled: false,
      });
      console.log('文件下载失败', error);
    });
  };
  render() {
    const { api_url, text, options, downFileBtnClass } = this.props;
    const { loadingStatus, buttonDisabled } = this.state;
    return (
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        className={downFileBtnClass}
        onClick={(event) => this.handleDownFile(event, api_url, options)}
        disabled={buttonDisabled}
      >
        {/* <Icon type={loadingStatus ? icon : 'loading'}/> */}
        {loadingStatus ? text : '下载中...'}
      </Button>
    );
  }
}

//定义属性 类型以及是否必填项
FileDown.proTypes = {
  api_url: PropTypes.isRequired
};
//定义属性的默认值
FileDown.defaultProps = {
  text: '文件下载',
  icon: 'download',
  //   downFileBtnClass: downFileBtn,
};
export default FileDown;