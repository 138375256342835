import { Result, Button, Row,Col } from "antd";
import {Link} from "react-router-dom"
const AddResult = ({prev}) => {
    // const history= useHistory()
    return (
        <Row>
            <Col span={8}>
            </Col>
            <Col span>
                <Result
       
                    status="success"
                    title="创建成功"
                    extra={[
                        (<Link to="/group/list" key="continue">
                        <Button type="primary" key="glist">群组列表</Button>
                        </Link>),
                        ( <Button key="con" onClick={prev} >继续创建</Button>)
                    ]}
                />
            </Col>
        </Row>
    )
}
export default AddResult