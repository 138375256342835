//分配可添加用户数
import { useEffect } from "react"
import { Form, Modal, Input, Statistic, InputNumber } from "antd"
import { SwapLeftOutlined } from "@ant-design/icons"
import { } from "../../../../api"
const RollBackNumAdd = ({ visible, onCancel, onSubmit, currentEdit }) => {
    const [form] = Form.useForm()
    const countValidator = (rule, value, callback) => { //value当前输入的值
        debugger
        if (currentEdit.numAddUsers < 1) {
            callback('该公司无可收回的添加用户数！')
        }
        else if (1 > value || value > currentEdit.numAddUsers) {
            callback(`请输入合法值1~${currentEdit.numAddUsers}`)
        }
        callback()
    }
    return (
        <Modal
            open={visible}
            title={<span>收回添加用户数<SwapLeftOutlined />{currentEdit.companyName}</span>}
            okText="回收"
            cancelText="取消"
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            okButtonProps={{ danger: true }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Statistic title="该公司现有数量" value={currentEdit.numAddUsers} />
            <Form
                form={form}
                layout="vertical"
                initialValues={{ assign_count: 0 }}

            >
                <Form.Item
                    name="assign_count"
                    label="分配数量"
                    rules={[
                        {
                            validator: countValidator
                        },
                    ]}
                >
                    <InputNumber  ></InputNumber>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RollBackNumAdd