//批量删除账号
import React, { useState,useEffect } from 'react';
import { Radio, Modal, Form, Input, Row, Col, Button } from 'antd';
import "./index.less"
const BatchDeleteAccount = ({ visible, onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    const [url,setUrl] =useState("/api/validate_code/get_validate_code?type=1")
    const handleOnClick=()=>{
        setUrl("/api/validate_code/get_validate_code?type=1&"+new Date().getTime())
    }
    useEffect(()=>{
        setUrl("/api/validate_code/get_validate_code?type=1&"+new Date().getTime())
    },[visible])
    return (
        <Modal
            open={visible}
            title="删除账号"
            okText="删除"
            cancelText="取消"
            onCancel={()=>{
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values);
                        form.resetFields()
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="inline"
                name="form_in_modal"
                initialValues={{ card_type: '1' }}
            >
                <Form.Item label="验证码" extra="We must make sure that your are a human.">
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="validate_code"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入正确的验证码',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <img src={url} onClick={handleOnClick} alt="" />
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default BatchDeleteAccount

