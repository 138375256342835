import React, { useEffect, useState, useLayoutEffect, useMemo } from "react"
import cookie from "react-cookies"
import { Layout, PageHeader, Result, Menu, Breadcrumb, theme } from 'antd';
import { Link, Outlet, useNavigate, matchRoutes, useLocation } from 'react-router-dom'
import XHeader from './XHeader'
import { HomeOutlined, ClusterOutlined, TeamOutlined, UserOutlined, AccountBookOutlined, SettingOutlined, FieldTimeOutlined, TagsOutlined } from "@ant-design/icons"
import { reqGetCompanyRights, reqGetCompany, reqGetGroup } from "../../api"
import store from "../../utils/storeUtils"
import { message } from "antd";
import logo from "../../assets/images/logo.png"
import SITE from "../../config/siteConfig"
import "./admin.css"
import { useDispatch } from "react-redux"
import { delete_group_by_id, update_companies, update_groups } from "../../redux/actions"
import SubMenu from "antd/lib/menu/SubMenu";
import router from "../../router";
const { Header, Footer, Sider, Content } = Layout;
const rootSubmenuKeys = ['home', 'account', 'group', 'company', 'card', 'system', 'log'];
const initMenuItems = [
    {
        label: '首页',
        key: '/home',
        icon: <HomeOutlined />,
    },
    {
        label: '账号管理',
        key: '/account',
        icon: <UserOutlined />,
        children: [
            {
                label: '查询修改账号',
                key: '/account/list',

            },
            {
                label: '终端信息',
                key: '/account/terminallist',
            },
            {
                label: '添加账号',
                key: '/account/add',
            },
        ]
    },
    {
        label: '公司管理',
        key: '/company',
        icon: <ClusterOutlined />,
        children: [
            {
                label: '公司列表',
                key: '/company/list',
            },
            {
                label: '新增公司',
                key: '/company/add',
            }, {
                label: '权限管理',
                key: '/company/rights',
            }
        ]
    },
    {
        label: '群组管理',
        key: '/group',
        icon: <TeamOutlined />,
        children: [
            {
                label: '查询修改群组',
                key: '/group/list',
            },
            {
                label: '新增群组',
                key: '/group/add',
            },
        ]
    }, {

        label: '充值卡',
        key: '/card',
        icon: <AccountBookOutlined />,
        children: [
            {
                label: '生成充值卡',
                key: '/card/create',
            },
        ]
    }, {
        label: '系统管理',
        key: '/system',
        icon: <SettingOutlined />,
        children: [
            {
                label: '系统设置',
                key: '/system/settings',
            },
        ]
    }, {

        label: '操作日志',
        key: '/logs',
        icon: <FieldTimeOutlined />,
        children: [
            {
                label: '用户数充值卡日志',
                key: '/logs/card',
            },
            {
                label: '账号修改日志',
                key: '/logs/account',
            }
        ]

    }
]
function AppLayout(props) {
    // 组件挂载调用
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [openKeys, setOpenKeys] = useState([]);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState([])
    const [isLogin, setIsLogin] = useState(false)
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const [menuItems, setMenuItems] = useState(initMenuItems)
    const [collapsed, setCollapsed] = useState(false)
    const {
        token: { colorBgContainer, borderRadiusLG, bodyBg },
    } = theme.useToken();
    useEffect(() => {
        const routes = matchRoutes(router, location.pathname)
        let pathArr = []
        if (routes !== null) {
            for (let route of routes) {
                let path = route.route.path
                if (path) {
                    pathArr.push(path)
                }
            }
        }
        if (pathArr.length > 0) {
            setOpenKeys(pathArr)
            setSelectedKeys(pathArr)
        }
    }, [location.pathname])
    useEffect(() => {  //当组件第一次被挂载执行副作用
        checkOn();
    }, [])
    useEffect(() => {
        if (isLogin) {
            if (store.getCompanyInfo()?.parent === 0) {
                const items = initMenuItems.concat([{
                    label: '受理订单',
                    key: '/order/list',
                    icon: <TagsOutlined />,
                }])
                setMenuItems(items)
            }
            checkDevices();
            getCompanyList();
            getGroups()
            saveRightsList()

        } else {
        }
    }, [isLogin])

    //获取选择群组列表
    const getGroups = async () => {
        const res = await reqGetGroup()
        if (res.code === 200) {
            dispatch(update_groups(res.data.group_list))
        }
    }
    //获取选择公司列表
    const getCompanyList = async () => {
        const res = await reqGetCompany()
        if (res.code === 200) {
            const currentCompany = res.data.company_info
            const subCompanys = res.data.subcompany_list
            dispatch(update_companies([currentCompany, subCompanys]))
        }
    }
    //检测是否登陆
    const checkOn = () => {
        if (!cookie.load(".SCAUTH")) {
            navigate("/login")
        } else {
            setIsLogin(true)
        }

    }
    const checkDevices = () => {
        var system = {
            win: false,
            mac: false,
            xll: false,
            ipad: false
        };
        var p = navigator.platform;
        system.win = p.indexOf("Win") === 0;
        system.mac = p.indexOf("Mac") === 0;
        system.x11 = (p === "X11") || (p.indexOf("Linux") === 0);
        system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false;
        //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面

        if (system.win || system.mac || system.xll || system.ipad) {
        } else {
            alert("请用电脑端访问站点,获得最佳视图")
        }
    }
    const saveRightsList = async () => {
        const res = await reqGetCompanyRights()
        if (res.code === 200) {
            store.saveCompanyInfo({ rights: res.data.list })
        }
    }
    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };
    const onMenuSelect = (menuItem) => {
        navigate(menuItem.key)
        setSelectedKeys([menuItem.key])
    }
    return (
        <Layout hasSider

            style={{
                minHeight: '100vh',
            }}
        >
            <Sider
                breakpoint='lg'
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    insetInlineStart: 0,
                    top: 0,
                    bottom: 0,
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'unset',
                }}
            >

                <div
                    className="logo"
                >
                    <img src={logo} alt="" />
                    {
                        collapsed || <h1 >{SITE.TITLE}</h1>
                    }
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    // defaultOpenKeys={defaultOpenKeys}
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    items={menuItems}
                    onSelect={onMenuSelect}
                >
                </Menu>
            </Sider>
            <Layout
                style={{ marginInlineStart: collapsed ? 80 : 200 }}
            >
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        height: 48,
                        display: 'flex'
                    }}
                >
                    <XHeader />
                </Header>
                <Content style={{ margin: '24px 16px 0', overflow: 'initial' }} >

                    <Outlet />

                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    {`Powered By Copyright Owners ${SITE.VERSION}`}
                </Footer>
            </Layout>
        </Layout >
        // <div className="layout-page">
        //     <div className="left-nav">
        //         {/* <Link to="/"> */}
        //         <div className="logo">
        //             <img src={logo} alt="" />
        //             <h1 >{SITE.TITLE}</h1>
        //         </div>
        //         {/* </Link> */}
        //         <Menu
        //             theme="dark"
        //             mode="inline"
        //             // defaultOpenKeys={defaultOpenKeys}
        //             selectedKeys={selectedKeys}
        //             openKeys={openKeys}
        //             onOpenChange={onOpenChange}
        //             items={menuItems}
        //             onSelect={onMenuSelect}
        //         >
        //         </Menu>
        //     </div>
        //     <div className="right-c">
        //         <Header></Header>
        //         <div className="content">
        //             <Outlet />
        //         </div>
        //         <div className="footer">{`Powered By Copyright Owners ${SITE.VERSION}`}</div>
        //     </div>
        // </div>
    )
}

export default AppLayout