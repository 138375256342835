import { Form, Input, Tooltip, Button, message } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import store from "../../../utils/storeUtils"
import { useEffect, useState } from "react"
import { reqEditCompany } from "../../../api"
const BasicSetting = () => {
    const [form] = Form.useForm()
    const [companyInfo, setCompanyInfo] = useState({})
    useEffect(() => {
        const { company_name, user_name, user_account, phone_num } = store.getCompanyInfo()
        form.setFieldsValue({ user_name, user_account, phone_num, name: company_name })
    }, [])
    const onFinish = async (values) => {
        delete values.user_account
        values.user_name = values.name
        const res = await reqEditCompany(values)
        if (res.data?.result === 1) {
            message.success("设置成功")
        } else {
            message.error("设置失败")
        }
    }
    return (
        <div className="basic-setting">
            <div className="basic-setting-header">
                <h1>基础设置</h1>
            </div>
            <div className="basic-setting-content">
                <div className="left">
                    <Form
                        form={form}
                        labelC
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="name"
                            label="公司名称"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: '公司名称是必需的',
                                }, {
                                    pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{1,9}$/,
                                    message: '必需是数字、英文字母或中文，且长度在1~9位之间'
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="phone_num"
                            label={<span>
                                联系电话&nbsp;
                                <Tooltip title="此号码会展示到手机管理台海报页面">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </span>}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="user_pwd"
                            label="新密码"
                            rules={[
                                { required: true, message: "密码必须填写" },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder="请输入密码" />
                        </Form.Item>
                        <Form.Item
                            name="confirm_pwd"
                            label="确认密码"
                            dependencies={['user_pwd']}
                            hasFeedback
                            rules={[
                                { required: true, message: "确认密码必须填写" },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('user_pwd') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('两次输入密码不一致!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="请输入确认密码" />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                xs: {
                                    span: 24,
                                    offset: 0,
                                },
                                sm: {
                                    span: 16,
                                    offset: 0,
                                },
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                更新设置
                            </Button>
                        </Form.Item>
                    </Form>

                </div>
                <div className="right"></div>
            </div>
        </div>
    )
}
export default BasicSetting