import { reqLogout } from "../../api"
import { Modal, Image, Button } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import img from "../../assets/images/avatar01.png"
import cookie from 'react-cookies'
import { useNavigate } from "react-router-dom"
import store from "../../utils/storeUtils"
const { confirm } = Modal
const Header = props => {
    const navigate = useNavigate()
    const handleClick = async () => {
        confirm({
            title: '确定要退出吗?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                logout()
            },
            onCancel() {

            },
        });
    }
    const logout = async () => {
        await reqLogout()
        store.clear()
        cookie.remove('.SCAUTH', { path: '/' })
        navigate("/login")
    }
    return (
        <div style={{
            flex: 1,
            display: "flex",
            justifyContent: 'flex-end',
            alignItems: 'center'
        }}>
            <span>欢迎，</span>
            <img src={img} alt="" height='24' width='24' />
            <span className="company-name">{store.getCompanyInfo()?.company_name}</span>
            <Button type="link" onClick={handleClick}>退出</Button>
        </div>
    )
}

export default Header