import React, { useState, useEffect, useMemo } from "react"
import { reqGetAccountType, reqGetGroup } from "../../../../api"
import {
    Form
    , Row
    , Col
    , Input
    , Button
    , Select
    , DatePicker,
    Space
} from 'antd';

import "./index.css"
import { useSelector } from "react-redux";
// import useGroups from "../../../../hooks/useGroups"
// import useCompany from "../../../../hooks/useCompany"
import { useAccountTypes } from "../../../../hooks/accountHooks"
const { RangePicker } = DatePicker
function SearchBar(props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [loadingGroups, setLoadingGroups] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const allGroups = useSelector(state => state.groups)
    const [groupList, setGroupList] = useState([])
    const [currentCompany, subCompanys] = useSelector(state => state.companies)
    const [accountTypes, loadingAccountTypes] = useAccountTypes()
    const onCompanychange = value => {
        setSelectedCompany(value)
        getGroupsByCompany(value)
    }
    const onFinish = (values) => {
        console.log(values)
        setLoading(true)
        //清楚空属性
        for (const key in values) {
            if (values[key] == undefined) {
                delete values[key]
            }
        }
        // //onSearch是async函数 n为返回的promist
        const resultPromise = props.onSearch(values)
        // //如果promisi是成功状态说明查询已经完毕
        resultPromise.then(() => {
            setLoading(false)
        })
    };
    const getGroupsByCompany = async (companyId) => {
        if (!companyId) return
        setLoadingGroups(true)
        const res = await reqGetGroup({ company_id: companyId })
        setLoadingGroups(false)
        if (res.code === 200) {
            setGroupList(res.data.group_list)
        }
    }
    const groups = useMemo(() => {
        if (selectedCompany) {
            return groupList
        } else {
            return allGroups
        }
    }, [allGroups, groupList, selectedCompany])
    const resetForm = () => {
        form.resetFields()
        setSelectedCompany(null)
    }
    return (
        <div className="account-list-searchBar">
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col lg={6}>
                        <Form.Item
                            label="终端号码"
                            name="terminalNum"
                        >
                            <Input placeholder="账号/IMEI/ICCID" />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item label="号码段" style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="startNum"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                            >
                                <Input placeholder="起始号码" />
                            </Form.Item>
                            <Form.Item
                                name="endNum"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                            >
                                <Input placeholder="结束号码" />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="账号名称"
                            name="name"
                        >
                            <Input placeholder="输入账号名称查询" />
                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <Form.Item
                            label="账号类型"
                            name="userType"
                            initialValue={-1}
                        >
                            <Select
                                loading={loadingAccountTypes}
                            >
                                <Select.Option value={-1}>全部</Select.Option>
                                {
                                    accountTypes.map(item => {
                                        return <Select.Option key={item.type} value={item.type}>{item.type_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="所属公司"
                            name="companyId"
                        >
                            <Select onChange={onCompanychange}
                                allowClear={true}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.indexOf(input) >= 0
                                }
                                placeholder="选择所属公司">

                                {
                                    currentCompany && <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                                }
                                {
                                    subCompanys && subCompanys.map(item => {
                                        //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                                        return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="所属群组"
                            name="groupId"
                        >
                            <Select
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.children.indexOf(input) >= 0
                                }
                                showSearch
                                loading={loadingGroups}
                                placeholder="选择所属群组">

                                {
                                    groups.map(item => {
                                        return <Select.Option key={item.group_id} value={item.group_id}>{item.group_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item name="range_time" label="到期时间" >
                            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                    <Col
                        lg={4}
                    >
                        <Space>
                            <Button loading={loading} type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button
                                onClick={resetForm}
                            >
                                重置
                            </Button>
                        </Space>

                    </Col>
                </Row>
            </Form>
        </div>
    )


}
export default SearchBar