import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Select, Space } from 'antd';
import { connect } from "react-redux"
import { reqGetGroup } from "../../../../api"
import "./index.css";
const SearchBar = (props) => {
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const onFinish = (values) => {
    //清楚空属性
    for (const key in values) {
      if (values[key] === undefined) {
        delete values[key]
      }
    }
    props.onSearch(values)
  };
  useEffect(() => {
    const getGroupsByCompany = async () => {
      const companyId = selectedCompany
      if (!companyId) return
      const res = await reqGetGroup({ company_id: companyId })
      if (res.code === 200) {
        setGroupList(res.data.group_list)
      }
    }
    getGroupsByCompany()
  }, [selectedCompany])

  const onCompanychange = value => {
    setSelectedCompany(value)
  }

  let { companies, groups } = props
  const [currentCompany, subCompanys] = companies
  if (selectedCompany) {
    groups = groupList
  }
  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={10}>
        <Col lg={6}>
          <Form.Item
            label="终端号码"
            name="terminalNum"
          >
            <Input placeholder="账号/IMEI/ICCID" />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item label="号码段" style={{ marginBottom: 0 }}>
            <Form.Item
              name="startNum"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input placeholder="起始号码" />
            </Form.Item>
            <Form.Item
              name="endNum"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
            >
              <Input placeholder="结束号码" />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            label="名称"
            name="name"
          >
            <Input placeholder="输入账号名称查询" />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            label="所属公司"
            name="companyId"
          >
            <Select onChange={onCompanychange}
              allowClear={true}
              showSearch
              filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
              }
              placeholder="选择所属公司">

              {
                currentCompany && <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
              }
              {
                subCompanys && subCompanys.map(item => {
                  //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                  return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            label="所属群组"
            name="groupId"
          >
            <Select
              allowClear={true}
              filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
              }
              showSearch
              placeholder="选择所属群组">

              {
                groups.map(item => {
                  return <Select.Option key={item.group_id} value={item.group_id}>{item.group_name}</Select.Option>
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col
          lg={10}
          style={{
            textAlign: 'right',
          }}
        >
          <Space>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              重置
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};



export default connect(state => ({
  companies: state.companies,
  groups: state.groups
}))(SearchBar)