import { Card, Form, Input, Button, message, Select } from "antd"
import { reqAddSubCompany, reqGetCompany } from "../../../api"
import { useNavigate } from "react-router-dom"
import store from "../../../utils/storeUtils"
import { update_companies } from "../../../redux/actions"

import "./index.less"
import { connect } from "react-redux"
const CompanyAdd = props => {
    const [form] = Form.useForm()
    const [currentCompany, subCompanys] = props.companies;
    const navigate = useNavigate()
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 6,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 6,
            },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };
    // 更新选择公司
    const updateReduxCompanies = async () => {
        const res = await reqGetCompany()
        if (res.code === 200) {
            const currentCompany = res.data.company_info
            const subCompanys = res.data.subcompany_list
            props.update_companies([currentCompany, subCompanys])
        }
    }
    const handleSubmit = async (values) => {
        values.userName = values.name
        const res = await reqAddSubCompany(values)
        if (res.success) {
            message.success("创建成功")
            navigate("/company/list")
            setTimeout(() => { //创建公司成功后 更新到redux中
                updateReduxCompanies()
            }, 500);
        } else {
            message.error(res.data?.err_msg)
        }
    }
    return (
        <div className="comapny-add">
            <Card title="新增子公司" bordered={false}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    scrollToFirstError
                    onFinish={handleSubmit}
                >
                    {
                        store.getCompanyInfo().parent === 0 && <Form.Item
                            label="所属公司"
                            name="parent"
                            rules={[
                                // { required: true, message: "必须选择所属公司" }
                            ]}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.indexOf(input) >= 0
                                }
                                placeholder="选择所属公司">

                                {
                                    <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                                }
                                {
                                    subCompanys.map(item => {
                                        //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                                        return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    }
                    <Form.Item
                        name="name"
                        label="公司名称"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '公司名称是必需的',
                            }, {
                                pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{1,9}$/,
                                message: '必需是数字、英文字母或中文，且长度在1~9位之间'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    {/* <Form.Item
                        name="user_name"
                        label="用户姓名"
                        dependencies={['account_model']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '必须填写姓名',
                            },
                        ]}

                    >
                        <Input placeholder="请输入姓名" />
                    </Form.Item> */}
                    <Form.Item
                        name="phoneNum"
                        label="电话"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="userAccount"
                        label="用户名"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '请输入账号名称!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input placeholder="请输入账号名称" />
                    </Form.Item>
                    <Form.Item
                        name="userPwd"
                        label="密码"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="请输入密码" />
                    </Form.Item>
                    <Form.Item
                        name="confirmPwd"
                        label="确认密码"
                        dependencies={['user_pwd']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '请输入确认密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('userPwd') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('两次输入密码不一致!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="请输入确认密码" />
                    </Form.Item>



                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
export default connect(
    state => ({
        companies: state.companies
    }),
    { update_companies }
)(CompanyAdd)