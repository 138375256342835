import { Drawer, Descriptions, Badge } from "antd"
import { useEffect, useState, useMemo } from 'react'
import { reqTerminalDetail } from "../../../api"
export default function Detail({ id, open, closeDetail }) {
    const [detailInfo, setDetailInfo] = useState({})
    useEffect(() => {
        async function getDetail() {
            const res = await reqTerminalDetail(id)
            if (res.success) {
                setDetailInfo(res.data)
            }
        }
        if (id !== undefined) {
            getDetail()
        }
    }, [id])

    const items = useMemo(() => {
        return [
            {
                key: 'eqpModel',
                label: '设备型号',
                children: detailInfo.eqpModel,
                labelStyle: {
                    padding: 0
                },
                span: 8
            },
            {
                key: 'eqpVer',
                label: '版本号',
                children: detailInfo.eqpVer,
                span: 8
            }, {
                key: 'latestLoginTime',
                label: '最后登录时间',
                children: detailInfo.latestLoginTime,
                span: 8
            }, {
                key: 'isOnline',
                label: '在线',
                children: detailInfo.isOnline ? <Badge status="success" text="在线" /> : <Badge status="default" text="离线" />,
                span: 8
            }
        ]
    }, [detailInfo])
    return <Drawer
        open={open}
        onClose={closeDetail}
    >
        <Descriptions

            items={items}
        />
    </Drawer>
}