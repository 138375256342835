
import { useState, useEffect } from "react"
import { reqGetAccountPrefix } from "../../../api"
import {
    Select,
    Card,
} from 'antd';

import SingleAdd from "./single-add"
import BatchAdd from "./batch-add"
import UploadAdd from "./upload-add"
import "./account-add.less"
const AccountAdd = props => {
    const [addModel, setAddModel] = useState("single")
    const [prefix, setPrefix] = useState("")
    //获取前缀
    useEffect(() => {
        const getAccountPrefix = async () => {
            const res = await reqGetAccountPrefix()
            setPrefix(res.data?.prefix)
        }
        getAccountPrefix()
    }, [])
    const tabList = [
        {
            key: 'single',
            tab: '单个添加',
        },
        {
            key: 'batch',
            tab: '批量添加',
        }, {
            key: 'upload',
            tab: 'Excel导入'
        }
    ];
    const handleTabChange = key => {
        setAddModel(key)
    }
    let inner;
    if (addModel === "single") {
        inner = <SingleAdd prefix={prefix} />
    } else if (addModel === "batch") {
        inner = <BatchAdd prefix={prefix} />
    } else {
        inner = <UploadAdd />
    }
    return (
        <div className="account-add">
            <Card className="card" tabList={tabList}
                onTabChange={handleTabChange}
                bordered={false}
            >
                {
                    inner
                }
            </Card>
        </div>
    )

}

export default AccountAdd