import { useEffect } from "react";
import { Modal, Form, Radio } from "antd";
const EditSpeechSecond = ({ visible, onCancel, onSubmit,limit_sencond }) => {
    const [form] = Form.useForm()
    useEffect(()=>{
        form.setFieldsValue({
            second:limit_sencond
        })
    },[limit_sencond,form])
    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            title="设置通话时长"
            onOk={() => {
                form
                .validateFields()
                .then(values => {
                        onSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}

        >
            <Form
                form={form}
            >
                <Form.Item
                name="second"
                    label="通话时长"
                >
                    <Radio.Group buttonStyle="solid" >
                        <Radio.Button value={30}>30s</Radio.Button>
                        <Radio.Button value={60}>60s</Radio.Button>
                        <Radio.Button value={120}>120s</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default EditSpeechSecond