import { useEffect, useState } from "react"
import { Col, Row, Statistic, Card, List, theme, Flex } from "antd"
import SITE from "../../config/siteConfig"
import { reqHomeInfo } from "../../api"
import { Tiny, Pie } from "@ant-design/plots"
import { CreditCardTwoTone } from "@ant-design/icons"
import "./index.css"
const Home = props => {
  const {
    token: { colorBgContainer, borderRadiusLG, bodyBg },
  } = theme.useToken();
  const boxStyle = {
    padding: 16,
    background: colorBgContainer,
    textAlign: 'center'
  }
  const [info, setInfo] = useState({})
  useEffect(() => {
    async function getInfo() {
      const res = await reqHomeInfo()
      if (res.success) {
        setInfo(res.data)
      }
    }
    getInfo()
  }, [])

  const percent = 1;
  const config = {
    percent,
    height: 180,
    width: 180,

    color: ['#E8EFF5', '#49B1C4'],
    annotations: [
      {
        type: 'text',
        style: {
          text: `子公司数量`,
          x: '50%',
          y: '45%',
          textAlign: 'center',
          fontSize: 16,
          fontStyle: 'bold',
        },
      }, {
        type: 'text',
        style: {
          text: `${info.subcompanyCount}`,
          x: '50%',
          y: '60%',
          textAlign: 'center',
          fontSize: 16,

        },
      }
    ],
  };
  const config1 = {
    height: 299,
    data: [
      { type: '激活账号', Count: info.activedCount },
      { type: '预警账号', Count: info.alarmCount },
      { type: '过期账号', Count: info.expiredCount },
    ],
    angleField: 'Count',
    colorField: 'type',
    innerRadius: 0.5,
    label: {
      text: ({ type, Count }) => {
        return `${type}: ${parseInt(Count)}`;
      },
      position: 'spider',
    },
    legend: {
      color: {
        title: true,
        rowPadding: 5,
      },
    },
    annotations: [
      {
        type: 'text',
        style: {
          text: `总: ${info.totalUserCount}`,
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize: 12,
          fontStyle: 'bold',
        },
      },
    ],
  };
  return <div className="home">
    <Row gutter={[16, 16]}   >
      <Col
        lg={6}
        xs={12}
      >
        <div className="box" style={boxStyle}>
          <Statistic title={<span>30天内过期</span>} value={info.alarmCount} />
        </div>
      </Col>
      <Col lg={6} xs={12} >
        <div className="box" style={boxStyle}>
          <Statistic title="已过期账号" value={info.expiredCount} />
        </div>
      </Col>
      <Col lg={6} xs={12} >
        <div className="box" style={boxStyle}>
          <Statistic title="已激活账号" value={info.activedCount} />
        </div>
      </Col>

      <Col lg={6} xs={12}>
        <div className="box" style={boxStyle}>
          <Statistic title="账号总数" value={info.totalUserCount} />
        </div>
      </Col>

      <Col lg={12} xs={24}>
        <Card title="充值卡详情" size="small">
          <Row align="middle" justify="space-around">
            <Col lg={12} xs={24} style={{ display: 'grid', placeItems: 'center' }}>
              <Tiny.Ring {...config} />
            </Col>
            <Col lg={12} xs={24}>
              <List>

                <List.Item >
                  <List.Item.Meta
                    avatar={<CreditCardTwoTone twoToneColor="#F76208" />}
                    title="语音年卡"
                  />
                  <span>{info.yearCardsCount}</span>
                </List.Item >

                <List.Item >
                  <List.Item.Meta
                    avatar={<CreditCardTwoTone twoToneColor="#009AB0" />}
                    title="语音月卡"
                  />
                  <span>{info.monthCardsCount}</span>
                </List.Item >

                <List.Item >
                  <List.Item.Meta
                    avatar={<CreditCardTwoTone twoToneColor="#FA031C" />}
                    title="语音永久卡"
                  />
                  <span>{info.permCardsCount}</span>
                </List.Item >

                <List.Item >
                  <List.Item.Meta
                    avatar={<CreditCardTwoTone twoToneColor="#F76208" />}
                    title="定位年卡"
                  />
                  <span>{info.locationYearCard}</span>
                </List.Item >

                <List.Item >
                  <List.Item.Meta
                    avatar={<CreditCardTwoTone twoToneColor="#009AB0" />}
                    title="定位月卡"
                  />
                  <span>{info.locationMonthCard}</span>
                </List.Item >
                <List.Item >
                  <List.Item.Meta
                    avatar={<CreditCardTwoTone twoToneColor="#009AB0" />}
                    title="可添加用户数"
                  />
                  <span>{info.numAddUsers}</span>
                </List.Item >
              </List>
            </Col>

          </Row>
        </Card>
      </Col>
      <Col lg={12} xs={24}>

        <Card title="设备状态占比" size="small">
          <Pie {...config1} />
        </Card>

      </Col>
      <Col span={24}>
        <Card
          title="链接"
          size="small"
        >
          <Row gutter={[16, 16]} justify="space-around">
            <Col lg={6} md={12} xs={24}>
              <Flex vertical align="center">
                <img src={require('./img/glt.jpg')} width={192} alt="" />
                <span>管理台小程序</span>
              </Flex>
            </Col>
            <Col lg={6} md={12} xs={24} >
              <Flex vertical align="center">
                <img src={require('./img/wl.jpg')} width={192} alt="" />
                <span>易洽物联卡</span>
              </Flex>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <Flex vertical align="center">
                <img src={require('./img/gzh.jpg')} width={192} alt="" />
                <span>公众号</span>
              </Flex>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <Flex vertical align="center">
                <img src={require('./img/wendang.png')} width={192} alt="" />
                <span>操作文档</span>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  </div >
}
export default Home
