/*
 通过接口文档定义接口函数
*/
import ajax from "./ajax"
const md5 = require("md5")
//登录
export const reqLogin = (user_account, pwd) => ajax("/api/company_user/login", { user_account, pwd: md5(pwd) }, "POST")
//登出
export const reqLogout = () => ajax("/api/company_user/logout", {}, "GET")
//公司首页
export const reqHomeInfo = () => ajax("/api/company/homeInfo", {}, "GET")
//查询修改用户
export const reqAccountList = (options = { company_id: 0, pageNum: 1 }) => ajax("/api/user/accountList", options, "GET")
//导入查询
export const reqQueryAccountByFile = file => ajax("/api/user/accountListByUpload", file, 'post')
//查询终端信息
export const reqTerminalList = (options = { company_id: 0, pageNum: 1 }) => ajax("/api/user/terminalList", options, 'GET')
//获取更多终端信息
export const reqTerminalDetail = (userId) => ajax('/api/user/terminalInfoMore', { userId }, "get")
// 查询修改用户-用户表查询-用户类型选择
export const reqGetAccountType = () => ajax("/api/user/get_user_type")
// 查询修改用户-用户表查询-所属集团选择
export const reqGetCompany = () => ajax("/api/company/get_company", {}, "GET")
//查询修改用户-获取选择群组 参数：company_id 可选
export const reqGetGroup = (options) => ajax("/api/user/get_group", options, "GET")
//批量激活
export const reqBatchRecharge = (userIds, serviceType, cardType) => ajax("/api/user/recharge", { userIds, serviceType, cardType }, "post")
//批量分配
export const reqBatchEditCompany = (user_ids, company_id) => ajax("/api/user/batch_edit_company", { user_ids, company_id }, "GET")
//批量设置默认群组
export const reqBatchEditDefaultGroup = (user_ids, group_id) => ajax("/api/user/batch_default_group", { user_ids, group_id }, "GET")
//批量重置账号
export const reqResetAccount = (userIds) => ajax('/api/user/resetting', { userIds }, "POST")
//批量设置位置服务地址
export const reqEditPosition = (options) => ajax("/api/user/settingLocationServer", options, "POST")
//批量修改密码
export const reqBatchEditAccountPwd = (user_ids, pwd) => ajax("/api/user/batch_user_pwd", { user_ids, pwd: md5(pwd) }, "GET")
//批量删除账号
export const reqBatchDeleteAccount = (user_ids, validate_code) => ajax("/api/user/batch_delete_users", { user_ids, validate_code }, "GET")
//获取验证码
export const reqGetValidateCode = type => ajax("/api/validate_code/get_validate_code", { type }, "GET")
//获取账号详情
export const reqGetAccountDetail = (user_id, company_id) => ajax("/api/user/get_user_detail", { user_id, company_id }, "GET")
//编辑账号信息
export const reqEditAccount = (options) => ajax("/api/user/edit_user", options)
//删除账号群
export const reqDeleteAccountGroup = (user_id, group_id) => ajax("/api/user/delete_user_group", { user_id, group_id })
//删除单个账号
export const reqDeleteAccount = (user_id, company_id) => ajax("/api/user/delete_user", { user_id, company_id })
// 设置定位
export const reqEditOpenPosition = (options) => ajax("/api/user/editOpenPosition", options, "POST")
//设置单呼
export const reqEditTempCall = (user_id, temp_call) => ajax("/api/user/edit_temp_call", { user_id, temp_call })
//获取账号前缀
export const reqGetAccountPrefix = () => ajax("/api/user/get_account_prefix", {})
//添加账号
export const reqAddAccount = options => ajax("/api/user/add_user", { ...options, pwd: md5(options.pwd), confirm_pwd: md5(options.confirm_pwd) })
//批量添加账号
export const reqBatchAddAccount = options => ajax("/api/user/batch_add_user", { ...options, pwd: md5(options.pwd), confirm_pwd: md5(options.confirm_pwd) })
//公司管理-获取公司列表
export const reqGetCompanyList = (options) => ajax("/api/company/companyList", options)
//根据当前公司充值卡数量
export const reqGetCardsCount = () => ajax("/api/company/cardsCount")
//获取当前公司权限
export const reqGetCompanyRights = () => ajax("/api/company/get_user_rights", [])
//分配可添加用户数
export const reqAssignNumAdd = (company_id, assign_count) => ajax("/api/company/assign_num_add_users", { company_id, assign_count })
//分配年卡
export const reqAssignCard = (options) => ajax("/api/company/assignCards", options)
//修改子公司
export const reqEditSubCompany = (options) => ajax("/api/company/addEditSubcompany", { ...options, userPwd: md5(options.userPwd), confirmPwd: md5(options.confirmPwd) })
//删除子公司
export const reqDeleteCompany = company_id => ajax("/api/company/delete_subcompany", { company_id })
//新增公司
export const reqAddSubCompany = (options) => ajax("/api/company/addEditSubcompany", { ...options, userPwd: md5(options.userPwd), confirmPwd: md5(options.confirmPwd) })
//获取子公司用户及权限
export const reqGetSubCompanyRights = (user_account, company_name) => ajax("/api/company/get_sub_company_user_rights", { user_account, company_name })
//保存公司权限
export const reqSaveRights = (user_id, rights) => ajax("/api/company/save_sub_company_user_rights", { user_id, rights })
//获取群组列表
export const reqGetGroupList = options => ajax("/api/group/get_list", options)
//编辑群组通话时长
export const reqEditSpeechSecond = (group_id, second) => ajax("/api/group/edit_group_speech_second", { group_id, second })
//删除群组
export const reqDeleteGroup = (group_id) => ajax("/api/group/delete_group", { group_id })
//获取成员列表
export const reqGetMember = (options = { page_index: 1 }) => ajax("/api/group/get_member", options)
//修改成员优先级
export const reqEditPriority = options => ajax("/api/group/edit_member_priority", options)
//批量移除成员
export const reqRemoveMember = options => ajax("/api/group/member_batch_delete", options)
//删除成员
export const reqRemoveSingleMember = (group_id, user_id) => ajax("/api/group/delete_member", { group_id, user_id })
//创建群组
export const reqCreateGroup = options => ajax("/api/group/add_group", options)
//生成年卡
export const reqCreateCard = (serviceType, cardType, count) => ajax("/api/company/createCards", { serviceType, cardType, count })
//获取公司海报
export const reqGetPoster = () => ajax("/api/setting/get_company_poster", {})
//保存海报
export const reqSavePoster = (file_url) => ajax("/api/setting/save_poster", { file_url })
//修改公司
export const reqEditCompany = (options) => ajax("/api/company/edit_company", { ...options, user_pwd: md5(options.user_pwd), confirm_pwd: md5(options.confirm_pwd) })
//获取群组名称
export const reqGetGroupName = (company_id, group_id) => ajax("/api/group/get_group_name", { company_id, group_id })
//修改群组名称
export const reqEditGroupName = (options) => ajax("/api/group/edit_group_name", options)
//导出群组成员
//用户修改日志
export const reqGetUserModifyLogs = (options = { page_index: 1 }) => ajax("/api/logs/get_user_modify_logs", options)
//用户数充值卡日志
export const reqGetUserCardLogs = (options = { page_index: 1 }) => ajax("/api/logs/get_user_card_logs", options)
//修改关联手机号
export const reqEditMobile = (user_id, mobile) => ajax("/api/user/edit_mobile", { user_id, mobile })
//修改备注
export const reqEditRemark = (user_id, remark) => ajax("/api/user/edit_remark", { user_id, remark })
//修改群组备注
export const reqEditGroupRemark = (group_id, remark) => ajax("/api/group/edit_remark", { group_id, remark })
//获取受理订单列表
export const reqUnicomOrderList = (options) => ajax("/api/order/unicomList", options)
//新增订单
export const reqCreateUnicomOrder = (options) => ajax("/api/order/unicomAdd", options)
//获取业务号码订单项目
export const reqUnicomOrderOps = keyWord => ajax("/api/order/unicomOptions", { bizNum: keyWord, pageNum: 1, pageSize: 50 })
//删除订单
export const reqDelteUnicomOrder = (id) => ajax("/api/order/unicomDelete", { id })
//出库
export const reqOutUnicom = options => ajax("api/user/outUnicomOrder", options, "POST")