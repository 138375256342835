import { useState } from "react"
import { connect } from "react-redux"
import {
    Form
    , Row
    , Col
    , Input
    , Button
    , Select
    , DatePicker
    , Space
} from 'antd';
import "./index.css"
const { RangePicker } = DatePicker;
const SearchBar = props => {
    //查询按钮加载中
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    //选择公司
    const [currentCompany, subCompanys] = props.companies;
    //当前选择的公司
    let [selectedCompany, setSelectedCompany] = useState(-1);
    //选择群组
    const onCompanychange = value => {
        setSelectedCompany(value)
    }
    const onFinish = (values) => {
        setLoading(true)
        //清楚空属性
        for (const key in values) {
            if (values[key]) {
                continue
            } else {
                delete values[key]
            }
        }
        //onSearch是async函数 n为返回的promist
        const resultPromise = props.onSearch(values)
        //如果promisi是成功状态说明查询已经完毕
        resultPromise.then(res => {
            setLoading(false)
        })
    };
    return (
        <div className="group-search-bar">
            <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col lg={6}>
                        <Form.Item
                            label="群组名称"
                            name="group_name"
                        >
                            <Input placeholder="输入群组名称" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            label="所属公司"
                            name="company_id"
                        >
                            <Select
                                allowClear={true}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.indexOf(input) >= 0
                                }
                                onChange={onCompanychange}
                                placeholder="选择所属公司">
                                {
                                    <Select.Option value={currentCompany.company_id}>{currentCompany.company_name}</Select.Option>
                                }
                                {
                                    subCompanys.map(item => {
                                        //这里必须有唯一的key属性 否者会引起报错 Each child in a list should have a unique "key" prop
                                        return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={8}>
                        <Form.Item name="range_time" label="创建时间" >
                            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                    <Col
                        lg={4}
                    >
                        <Space>
                            <Button loading={loading} type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button

                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default connect(
    state => ({ companies: state.companies })
)(SearchBar)