import { Modal, Form, Select, message } from "antd";
import { reqOutUnicom, reqUnicomOrderOps } from "../../../../api";
import { useState } from "react"
let timeout;
let currentValue;
export default function BatchOutUnicom({ open, close, userIds }) {
    const [form] = Form.useForm()
    const [list, setList] = useState([])
    // const []
    const handleSearch = value => {
        getOptions(value)
    }
    const handleChange = () => {
    }
    const getOptions = (value) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = value;
        if (value) {
            timeout = setTimeout(() => {
                fetchOps()
            }, 300);
        } else {
            setList([])
        }


        const fetchOps = async () => {
            const res = await reqUnicomOrderOps(value)
            console.log(currentValue === value);
            if (currentValue === value && res.success) {
                setList(res.data.list)
            }
        }
    }
    return <Modal
        title="设备出库"
        open={open}
        onCancel={() => {
            setList([])
            close()
        }}
        onOk={() => {
            form.validateFields().then(async values => {
                console.log(values);
                const ops = {
                    userIds,
                    orderId: values.orderId
                }
                const res = await reqOutUnicom(ops)
                if (res.success) {
                    message.success("出库成功！")
                    close()
                } else {
                    message.error(res.msg)
                }
            })
        }}
        destroyOnClose
    >
        <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            preserve={false}
        >
            <Form.Item label="业务号码" name="orderId" rules={[{ required: true }]}>
                <Select
                    showSearch
                    onSearch={handleSearch}
                    onChange={handleChange}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    notFoundContent={null}
                    filterOption={false}
                    filterOption={false}
                    options={(list || []).map((d) => ({
                        value: d.id,
                        label: d.bizNum,
                    }))}
                />
            </Form.Item>
        </Form>
    </Modal>
}