
import {
    UPDATE_COMPANIES,
    UPDATE_GROUPS,
    DELETE_GROUP_BYID
    , DELETE_COMPANY_BYID
} from "./action_types"

import { combineReducers } from "redux"
function companies(state = [], action) {
    switch (action.type) {
        case UPDATE_COMPANIES:
            return [...action.data]
        case DELETE_COMPANY_BYID:
            let index = state[1].findIndex(c => c.company_id === action.data)
            if (index !== -1) {
                state[1].splice(index, 1)
            }
            return state
        default:
            return state;
    }
}

function groups(state = [], action) {
    switch (action.type) {
        case UPDATE_GROUPS:
            return [...action.data]
        case DELETE_GROUP_BYID:
            let index = state.findIndex(g => g.group_id === action.data)
            if (index !== -1) {
                state.splice(index, 1)
            }
            return state
        default:
            return state;
    }
}

export default combineReducers({ companies, groups })