import { useEffect } from "react"
import { Card, Space, Button, Input, Select, Table, message, Popconfirm, Upload } from "antd"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons"
import { reqGetMember, reqEditPriority, reqRemoveMember, reqRemoveSingleMember } from "../../../api";
import "./index.less"
import { useState } from "react"
import RemoveMember from "./remove-member"
import FileDown from "../../../components/file-down/file-down"
const { Option } = Select

const GroupMember = props => {
    const navigate = useNavigate()
    const params = useParams()
    const { group_id } = params
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [searchType, setSearchType] = useState("user_account")
    //分页状态
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ current: 1, pageSize: 30 });
    const [options, setOptions] = useState({})
    const [removeVisible, setRemoveVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        (async function () {
            const { current, pageSize } = page
            setLoading(true)
            const res = await reqGetMember({ ...options, group_id, page_size: pageSize, page_index: current })
            setLoading(false)
            if (res.code === 200) {
                setData(res.data.member_list)
                setTotal(res.data.row_count)
            }
        })()
    }, [group_id, page, options])
    const onSearch = value => {
        const { current, pageSize } = page
        setOptions({ ...options, [searchType]: value })
        setPage({ current: 1, pageSize })
    }
    //编辑优先级
    const editPriority = async (value, record) => {
        const priority = value
        const { user_id } = record
        const res = await reqEditPriority({ user_id, group_id, priority })
        if (res.data?.result === 1) {
            message.success("优先级设置成功")
        }
    }
    const onSelectChange = (keys) => {
        setSelectedRowKeys(keys)
    }
    const updataTable = async () => {
        const { current, pageSize } = page
        setLoading(true)
        const res = await reqGetMember({ ...options, group_id, page_size: pageSize, page_index: current })
        setLoading(false)
        if (res.code === 200) {
            setData(res.data.member_list)
            setTotal(res.data.row_count)
        }
    }
    // 分页、排序、筛选变化时触发
    const handleTableChange = page => {
        //改变state 会重新执行 useEffect副作用（发起异步请求获取UserList）
        setPage(page)
        //分页状态变化 清空当前列表数据 否则可能会引起 total总数据长度 大于 pageSize
        //  Warning: [antd: Table] `dataSource` length is less than `pagination.total` but large than `pagination.pageSize`. Please make sure your config correct data with async mode. 
        setData([])
    }
    //取消移除成员
    const cancelRemove = () => {
        setRemoveVisible(false)
    }
    //确认删除成员
    const confirmDeleteSingleMember = async (record) => {
        const { user_id } = record
        const res = await reqRemoveSingleMember(group_id, user_id)
        if (res.data.result === 1) {
            message.success("删除成功")
            updataTable()
        }

    }
    //确认移除成员
    const comfirmRemoveMemver = async (values) => {
        const user_ids = selectedRowKeys.join(',')
        const validate_code = values.validate_code
        const res = await reqRemoveMember({ group_id, user_ids, validate_code })
        if (res.data?.result === 1) {
            setRemoveVisible(false)
            message.success("删除成功")
            // 更新表格
            // 方法1
            // let indexs = []
            // selectedRowKeys.forEach(element => {
            //     const index = data.findIndex(item => item.user_id === element)
            //     indexs.push(index)
            // });
            // const newData = data.filter((item,index) => {
            //     return indexs.indexOf(index) === -1
            // })
            // setData(newData)
            // 方法2
            updataTable()
        } else {
            message.error("验证码不正确")
        }

    }
    //导入
    const onChange = (info) => {
        if (info.fileList.length > 1) {
            info.fileList.shift()
        }
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            const { code, data } = info.file.response
            if (code === 200) {
                if (data.result === 1) {
                    message.success(`${info.file.name}导入成功`)
                    updataTable()
                } else {
                    message.error(data.err_msg)
                }
            }

        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: "登录账号",
            dataIndex: "user_account",
            align: 'center'
        },
        {
            title: "中文名",
            dataIndex: "name",
            align: 'center'
        },
        {
            title: "所属公司",
            dataIndex: "company_name",
            align: 'center'
        },
        {
            title: "优先级",
            dataIndex: 'priority',
            align: 'center',
            render: (value, record) => {
                return (
                    <Select
                        defaultValue={value}
                        onChange={v => { editPriority(v, record) }}
                    >
                        <Option value={1}>普通</Option>
                        <Option value={2}>中级</Option>
                        <Option value={3}>高级</Option>
                    </Select>
                )
            }
        },
        {
            title: "操作",
            width: 200,
            align: 'center',
            render: (record) => {
                return (
                    <Popconfirm
                        title="确认要移除该成员？"
                        onConfirm={() => { confirmDeleteSingleMember(record) }}
                        okText="移除"
                        cancelText="取消"

                    >
                        <Button type="link" >移除成员</Button>
                    </Popconfirm>
                )
            }
        },
    ]
    const title = (
        <>
            <Button type="link" onClick={() => { navigate(-1) }}>
                <ArrowLeftOutlined />
            </Button>
            <span style={{ marginLeft: 20 }}>成员管理</span>
        </>
    )
    const extra = (
        <>
            <Space>
                <Select
                    defaultValue={0}
                    onChange={value => {
                        setOptions({ ...options, priority: value })
                    }}
                >
                    <Option value={0}>全部</Option>
                    <Option value={1}>普通</Option>
                    <Option value={2}>中级</Option>
                    <Option value={3}>高级</Option>
                </Select>
                <Select
                    style={{
                        width: 120
                    }}
                    value={searchType}
                    onChange={(value) => {
                        setSearchType(value)
                    }}
                >
                    <Option value="user_account">按账号查询</Option>
                    <Option value="user_name">按名称查询</Option>
                </Select>
                <Input.Search
                    allowClear
                    onSearch={onSearch}
                ></Input.Search>
                <FileDown api_url={`/api/group/get_member_export?group_id=${group_id}`} text="导出"></FileDown>
                {/* <Button type="primary" onClick={exportExcel}>导出</Button> */}
                <Upload
                    name="file"
                    action="/api/group/member_upload"
                    onChange={onChange}
                    accept=".xlsx"
                >
                    <Button icon={<UploadOutlined />}>导入</Button>
                </Upload>,
            </Space>
        </>
    )
    return (
        <div className="group-member">
            <RemoveMember visible={removeVisible} onCancel={cancelRemove} onSubmit={comfirmRemoveMemver}></RemoveMember>
            <Card
                bordered={false}
                title={title}
                extra={extra}
            >
                <Table
                    size="small"
                    loading={loading}
                    dataSource={data}
                    rowKey="user_id"
                    rowSelection={
                        {
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }
                    }
                    columns={columns}
                    title={() => {
                        return (
                            <Space>
                                <Button disabled={!hasSelected} onClick={() => { setRemoveVisible(true) }}>移除成员</Button>
                                {hasSelected ? <span>已选中 {selectedRowKeys.length} 行</span> : null}
                            </Space>
                        )
                    }}
                    pagination={{ total: total, defaultPageSize: page.pageSize, pageSizeOptions: [10, 30, 50, 100], current: page.current, showTotal: total => `总记录： ${total} 条`, showQuickJumper: true, position: ["bottomCenter"] }}
                    onChange={handleTableChange}
                    bordered
                >
                </Table>
            </Card>
        </div>
    )
}
export default GroupMember