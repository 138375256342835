//批量激活模态框组件
import React, { useState } from 'react';
import { Radio, Modal, Form, Select,Input } from 'antd';
const EditMobile = ({ visible, onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            open={visible}
            title="关联手机号"
            okText="确定"
            cancelText="取消"
            onCancel={()=>{
                form.resetFields()
                onCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onSubmit(values,form);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="inline"
                name="form_in_modal"
            >
                <Form.Item
                    name="mobile"
                    label="关联手机号"
                >
                    <Input></Input>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default EditMobile

